import React, { useState, useEffect } from 'react';
import { userAppData } from '../MainApp.js';
import { accessBoolean } from '../func.js';
import './QuickLinks.css';


import { OnCallAddNewCallFormModal } from '../common/modals.js';
import { FaBell } from 'react-icons/fa';

export default function QuickLinks() {
    const [onCallModalActiveState, setOnCallModalActiveState] = useState(false);

    const oncallLink = accessBoolean(userAppData['onCall']['perms']);

    function modalClose() {
        setOnCallModalActiveState(false);
    };

    function newOnCallButtonClicked() {
        if (onCallModalActiveState) {
            setOnCallModalActiveState(false);
        } else {
            setOnCallModalActiveState(true);
        }
    }

    return (
        <div className='QuickLinks_main_div'>

            <OnCallAddNewCallFormModal modalActiveState={onCallModalActiveState} setStateFunction={setOnCallModalActiveState} onModalClose={modalClose} />
            <h1 className='dashboard_tabHeaders'>Quick Links</h1>

            {oncallLink && (
                <div onClick={() => newOnCallButtonClicked()} className='QuickLinks_LinkDiv'>

                    <FaBell color='#ffffff' size={50} />
                    <p className='QuickLinks_LinkText'>New Oncall Request</p>

                </div>)}


        </div>
    )
};