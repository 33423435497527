import React from 'react';
import { useTable, useSortBy } from 'react-table';
import { useNavigate } from 'react-router-dom';



export function PupilCallTypeSummaryTable({ pupilRecords, callTypes }) {

    const navigate = useNavigate();  // Hook for navigation

    const columns = React.useMemo(() => [
        {
            Header: "Pupil's Name",
            accessor: 'pupilName'  // Define how to get the cell's value
        },
        ...callTypes.map(type => ({
            Header: type.typeName,
            accessor: d => d.totals[type.typeName],
            sortType: 'basic'
        })),
        {
            Header: 'Total Calls',
            accessor: 'totals.total',
            sortType: 'basic'
        }
    ], [callTypes]);

    const data = React.useMemo(() => pupilRecords.map(record => ({
        ...record,
        totals: callTypes.reduce((acc, type) => ({
            ...acc,
            [type.typeName]: record.onCallRecords.filter(call => call.callType === type.typeName).length
        }), { total: record.onCallRecords.length })
    })), [pupilRecords, callTypes]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({ columns, data }, useSortBy);

    // Render the UI for your table
    return (
        <table {...getTableProps()} className="summary-table">
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <span>
                                    {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps({
                            onClick: () => navigate(`/app/oncall/reports/individual/${row.original.id}`)
                        })}>
                            {row.cells.map(cell => (
                                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            ))}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};