import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import './App.css';
import './desktop.css';
import './mobile.css';
import logo from './SE_Logo.svg';
import logoMin from './SE_min_logo.svg';
import devices from './SE_Mockup1.png';
import { createBrowserRouter, Route, Link, createRoutesFromElements, RouterProvider } from "react-router-dom";
import MainApp from './mainApp/MainApp.js'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import Dashboard from './mainApp/dashboard/Dashboard.js';

import SysManagerHome from './mainApp/sysManager/SysManagerHome.js';

import ItSupport from './mainApp/itSupport/ItSupport.js';
import ItSupportForm from './mainApp/itSupport/ItSupportForm.js';
import ItRequests from './mainApp/itSupport/ItRequests.js';
import ItSrv from './mainApp/itSupport/ItRequestSingleView.js';

import Maintenance from './mainApp/maintenance/Maintenance.js';
import MaintenanceForm from './mainApp/maintenance/MaintenanceForm.js';
import MaintenanceRequests from './mainApp/maintenance/MaintenanceRequests.js';
import MaintenanceSrv from './mainApp/maintenance/MaintenanceSingleView.js';

import Oncall from './mainApp/oncall/Oncall.js';
import OncallHome from './mainApp/oncall/OncallHome.js';
import OncallPreviousRequests from './mainApp/oncall/OncallPreviousRequests.js';
import OncallReports from './mainApp/oncall/OncallReports.js';
import OnCallReportsIndividualPupil from './mainApp/oncall/OnCallReportsIndividualPupil.js';
import OnCallReportGroupSummary from './mainApp/oncall/OnCallReportGroupSummary.js';

import ReproRequests from './mainApp/reprographics/ReproRequests.js';
import ExternalLinks from './mainApp/externalLinks/externalLinks.js';

import SeInvnt from './mainApp/seInvnt/SeInvnt.js';
import SeInvntDashboard from './mainApp/seInvnt/SeInvntDashboard.js';
import SeInvntDevice from './mainApp/seInvnt/SeInvntDevice.js';
import SeInvntReports from './mainApp/seInvnt/SeInvntReports.js';

import BookingsHome from './mainApp/bookings/bookingsHome.js';

// REACT ICONS
import { IoPeople } from "react-icons/io5";
import { MdNotificationsActive } from "react-icons/md";
import { BsClockHistory } from "react-icons/bs";

class MainNav extends Component {

  render() {
    return (
      <Navbar expand="lg" className="sticky-nav" style={{ position: "sticky" }}>
        <Container>
          <Navbar.Brand href="/">
            <img
              alt=""
              src={logo}
              height="50"
              className="d-inline-block align-top"
            />{' '}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link href="/support"><div className='school_login_button'>Support</div></Nav.Link>
              <Nav.Link href="/app"><div className='school_login_button'>School Login</div></Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
  }
};

class MainSite extends Component {

  render() {

    return (
      <div>
        <MainNav />

        <div className='MainSiteDiv'>

          <div className="mainSiteWhiteDiv1">
            <div className="mainSiteTextDiv">
              <h1 className="mainSiteHeading2">The Modular School Support System </h1>
              <img src={devices} className='devicesImage' alt='Devices'></img>
            </div>
          </div>

          <div className="mainSiteGreyDiv">
            <h1 className="mainSiteHeading3">Streamline Staff Workflows & Save Time with School Engine</h1>
            <div className="mainSitePdiv">
              <div className="mainSiteSinglePdiv"><IoPeople size={70} className="mainSitePIcon" /><p className="mainSitePtext">School Engine streamlines staff workflows by providing a dedicated platform for logging all support requests, from IT issues to SLT Calls.</p></div>
              <div className="mainSiteSinglePdiv"><BsClockHistory size={70} className="mainSitePIcon"/><p className="mainSitePtext">Eliminating the need for phone calls, running around or lost messages, ensuring every issue is tracked and addressed efficiently.</p></div>
              <div className="mainSiteSinglePdiv"><MdNotificationsActive size={70} className="mainSitePIcon"/><p className="mainSitePtext">Automated processes further streamline operations by sending notifications and updates, freeing up valuable staff time for more important tasks.</p></div>
            </div>
          </div>


          <div className="mainSiteWhiteDiv">
            <h1 className="mainSiteHeading3">Reduce Paperwork & Boost Transparency with School Engine's Modules</h1>
            <div className="mainSiteColumnDiv">
              <div className="mainSiteCol1">
                <div className="mainSiteCol1Mod"><h2 className="mainSiteModuleHeading">IT Support Desk</h2></div>
                <div className="mainSiteCol1Mod"><h2 className="mainSiteModuleHeading">Reprographics Requests</h2></div>
                <div className="mainSiteCol1Mod"><h2 className="mainSiteModuleHeading">Admin Requests</h2></div>
                <div className="mainSiteCol1Mod"><h2 className="mainSiteModuleHeading">SE Forms</h2></div>
              </div>
              <div className="mainSiteCol2">
                <div className="mainSiteCol1Mod"><h2 className="mainSiteModuleHeading">Room Bookings</h2></div>
                <div className="mainSiteCol1Mod"><h2 className="mainSiteModuleHeading">Maintenance Requests</h2></div>
                <div className="mainSiteCol1Mod"><h2 className="mainSiteModuleHeading">DBS Reminder</h2></div>
                <div className="mainSiteCol1Mod"><h2 className="mainSiteModuleHeading">Reception Assistant</h2></div>
              </div>
              <div className="mainSiteCol3">
                <div className="mainSiteCol1Mod"><h2 className="mainSiteModuleHeading">SE Inventory</h2></div>
                <div className="mainSiteCol1Mod"><h2 className="mainSiteModuleHeading">School Policy Manager</h2></div>
                <div className="mainSiteCol1Mod"><h2 className="mainSiteModuleHeading">CCTV Request Logger</h2></div>
                <div className="mainSiteCol1Mod"><h2 className="mainSiteModuleHeading">Medical Incident Log</h2></div>
              </div>
            </div>
          </div>
          <div className="mainSiteWhiteDiv">

          </div>

        </div>
      </div>
    )
  }
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route index element={<MainSite />} /> {/* index prop is the same as path="/"*/}

      <Route exact path="support" element={<MainApp />} />

      <Route exact path="app" element={<MainApp />}>
        <Route index element={<Dashboard />} />
        <Route path="dashboard" element={<Dashboard />} />

        <Route path="ItSupport" element={<ItSupport />}>
          <Route index element={<ItSupportForm />} />
          <Route path="form" element={<ItSupportForm />} />
          <Route path="requests" element={<ItRequests />} />
          <Route path="reports" element={<ItSrv />} />
          <Route path="requests/srv" element={<ItSrv />} />
        </Route>

        <Route path="ReproRequests" element={<ReproRequests />} />

        <Route path="seInvnt" element={<SeInvnt />}>
          <Route index element={<SeInvntDashboard />} />
          <Route path="device" element={<SeInvntDevice />} />
          <Route path="reports" element={<SeInvntReports />} />
        </Route>

        <Route path="bookings/:group" element={<BookingsHome />} />

        <Route path="maintenance" element={<Maintenance />}>
          <Route index element={<MaintenanceForm />} />
          <Route path="requests" element={<MaintenanceRequests />} />
          <Route path="form" element={<MaintenanceForm />} />
          <Route path="requests/srv" element={<MaintenanceSrv />} />
          <Route path="reports" element={<MaintenanceForm />} />
        </Route>

        <Route path="oncall" element={<Oncall />}>
          <Route index element={<OncallHome />} />
          <Route path="archive" element={<OncallPreviousRequests />} />
          <Route path="reports" element={<OncallReports />} />
          <Route path="reports/individual/:pupilId" element={<OnCallReportsIndividualPupil />} />
          <Route path="reports/:type/:groupName" element={<OnCallReportGroupSummary />} />
          <Route path="reports/wholeSchool" element={<OnCallReportsIndividualPupil />} />
        </Route>

        <Route path="sysmanager" element={<SysManagerHome />}>
          <Route index element={<SysManagerHome />} />
          <Route path="staff" element={<MaintenanceRequests />} />
          <Route path="learner" element={<MaintenanceForm />} />
          <Route path="bookings" element={<MaintenanceSrv />} />
          <Route path="system" element={<MaintenanceForm />} />
        </Route>

      </Route>
    </Route>
  )
)

function App() {


  return (


    <div className="mainSite">
      <RouterProvider router={router} />
    </div>


  );
}

export default App;