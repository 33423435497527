import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import './OnCallReportsIndividualPupil.css';
import LoadingComp from '../common/loading.js';
import { ApiError } from '../common/apiError.js';
import { SEC_ApiCall, thisWeekEpochRange, lastWeekEpochRange, epochToReadable } from '../func.js';
import { PieChartComponent } from './chartComponents/PieChartComponent';
import { findSchoolPeriod } from './OnCallFunc.js';
import { OnCallCompleteCall } from './OnCallCompleteCall.js';

import { LessonTimesCountTable } from './tableComponents/LessonTimesCountTable.js';
import { WeeklyCallTrendsLineChart } from './chartComponents/WeeklyCallTrendsLineChart.js';
// import exportFromJSON from 'export-from-json';


// move to func.js
/*
function exportOnCallRecords(csvData, pupilName) {
    const newArray = csvData.map(item => {
        // Create a new object with all properties except "notes"
        const { notes, ...newItem } = item;
        return newItem;
      });

    const fileName = 'OnCall-Records_' + pupilName.replace(/ /g,"_");
    const exportType =  exportFromJSON.types.csv;
    const data = Object.values(newArray)
    exportFromJSON({ data, fileName, exportType });
}
*/




export default function OnCallReportsIndividualPupil() {
    const [dataState, setDataState] = useState([]);
    const [isLoadedState, setIsLoadedState] = useState(false);
    const [apiErrorState, setApiErrorState] = useState(null);
    const [apiErrorTextState, setApiErrorTextState] = useState('');

    const [filteredDataState, setFilteredDataState] = useState(null);
    const [selectedLesson, setSelectedLesson] = useState(null);
    const [selectedCallType, setSelectedCallType] = useState(null);

    const [totalCallCountState, setTotalCallCountState] = useState(0);
    const [allCallsArrayState, setAllCallsArrayState] = useState(null);
    const [callTypeColoursState, setAllCallTypeColoursState] = useState(null);

    function handleLessonSelect(selectedPeriod) {
        /*
        // Check if the selected period is already the active filter
        if (selectedPeriod === selectedLesson) {
            // If so, reset by clearing the selected lesson and using original data
            setSelectedLesson(null);
            setFilteredDataState(dataState);  // Reset to original data
            let allUnfilteredRecords = dataState.pupilRecords.flatMap(pupil => pupil.onCallRecords);
            setAllCallsArrayState(allUnfilteredRecords);
            setTotalCallCountState(allUnfilteredRecords.length);
        } else {
            // Set the new selected lesson and filter data
            setSelectedLesson(selectedPeriod);

            // Filter pupil records and their call records based on the selected lesson period
            const filteredPupils = dataState.pupilRecords.map(pupil => ({
                ...pupil,
                onCallRecords: pupil.onCallRecords.filter(call => {
                    const callPeriod = findSchoolPeriod(call.submissionEpoch, dataState.timetableTimings);
                    return callPeriod === selectedPeriod;
                })
            })).filter(pupil => pupil.onCallRecords.length > 0); // Keep only pupils with calls in that period

            // Collect all filtered call records for the pie chart
            const allFilteredRecords = [];
            filteredPupils.forEach(pupil => {
                allFilteredRecords.push(...pupil.onCallRecords);
            });

            // Update filtered data state to only include pupils with calls in the selected period
            setFilteredDataState({
                ...dataState,
                pupilRecords: filteredPupils
            });

            // Update the state used for the pie chart and other visualizations
            setAllCallsArrayState(allFilteredRecords);
            setTotalCallCountState(allFilteredRecords.length);
        } */
    };

    function handleCallTypeSelect(selectedType) {
        /*
        if (selectedType === selectedCallType) {
            // If the same type is clicked again, reset the filters
            setSelectedCallType(null);
            setFilteredDataState(dataState);  // Reset to original data
            let allUnfilteredRecords = dataState.pupilRecords.flatMap(pupil => pupil.onCallRecords);
            setAllCallsArrayState(allUnfilteredRecords);
            setTotalCallCountState(allUnfilteredRecords.length);
        } else {
            // Set the new selected call type and apply filter
            setSelectedCallType(selectedType);  // Set this to selectedType, not null

            // Filter pupil records and their call records based on the selected call type
            const filteredPupils = dataState.pupilRecords.map(pupil => ({
                ...pupil,
                onCallRecords: pupil.onCallRecords.filter(call => call.callType === selectedType)
            })).filter(pupil => pupil.onCallRecords.length > 0);  // Keep only pupils with calls of that type

            // Collect all filtered call records for the pie chart
            const allFilteredRecords = filteredPupils.flatMap(pupil => pupil.onCallRecords);

            // Update filtered data state to only include pupils with calls of the selected type
            setFilteredDataState({
                ...dataState,
                pupilRecords: filteredPupils
            });

            // Update the state used for the pie chart and other visualizations
            setAllCallsArrayState(allFilteredRecords);
            setTotalCallCountState(allFilteredRecords.length);
        }*/
    };

    async function apiCall(params) {

        var url = 'oncall/reports/pupil';
        try {
            // API CALL
            const response = await SEC_ApiCall(url, params, setApiErrorState);
            response.onCallRecords.sort((a, b) => b.submissionEpoch - a.submissionEpoch); // sorts records to newest first
            setDataState(response);
            setFilteredDataState(response['onCallRecords']);
            // counting and calculations for states
            setTotalCallCountState(response['onCallRecords'].length);

            /*
                        // this week
                        let thisWeekCounter = 0;
                        let thisWeekEpochValues = thisWeekEpochRange();
                        let thisWeekLowerThresholdEpoch = thisWeekEpochValues['lower'];
                        let thisWeekUpperThresholdEpoch = thisWeekEpochValues['upper'];
            
                        // last week
                        let lastWeekCounter = 0;
                        let lastWeekEpochValues = lastWeekEpochRange();
                        let lastWeekLowerThresholdEpoch = lastWeekEpochValues['lower'];
                        let lastWeekUpperThresholdEpoch = lastWeekEpochValues['upper'];
            */

            // sets the data states for the pie chart component
            setAllCallsArrayState(response['onCallRecords']); // full array of all calls
            setAllCallTypeColoursState(response['onCallType']); // call type colour codes from api call

            setIsLoadedState(true);
        } catch (e) {
            setIsLoadedState(true);
            setApiErrorState('error');
            setApiErrorTextState(e);
            console.log(e);
        }
    };

    const { pupilId } = useParams();

    useEffect(() => {
        var params = {
            'pupilId': pupilId
        };
        apiCall(params);
    }, []);

    function apiCallRefresh() {
        var params = {
            'pupilId': pupilId
        };
        apiCall(params);
    };



    if (!isLoadedState) {
        return (
            <div className='main_div'>
                <LoadingComp />
            </div>
        )
    } else if (apiErrorState) {
        return (
            <div className='main_div'>
                <ApiError />
            </div>
        )
    } else {


        return (
            <div className='main_div'>
                <div className='internal_main_div'>
                    <div className='oncall_header_div'>
                        <h1 className='admin_header' >OnCall Report - {dataState['pupilName']}</h1>
                        <h1 className='oncall_reports_individual_subHeader'>Total Calls: {totalCallCountState}</h1>
                    </div>
                    <div className='oncall_reports_individual_main_div'>

                        
                        
                        <div className='oncall_reports_individual_summaryDiv'>

                            <div className='oncall_reports_individual_pieChartDiv'>
                                <h1 className='oncall_reports_individual_divHeader'>Call Types</h1>
                                <h1 className='oncall_reports_individual_divSubHeader'>Click to filter</h1>
                                <PieChartComponent
                                    callsArray={allCallsArrayState}
                                    callTypeColours={callTypeColoursState}
                                    onCallTypeSelect={handleCallTypeSelect}  // Pass this function as a prop
                                />
                            </div>

                            <div className='oncall_reports_individual_tableDiv'>
                                <h1 className='oncall_reports_individual_divHeader'>Lesson Times</h1>
                                <h1 className='oncall_reports_individual_divSubHeader'>Based on call submission time</h1>
                                <h1 className='oncall_reports_individual_divSubHeader'>Click a lesson to filter and click it again to reset</h1>
                                <LessonTimesCountTable
                                    data={allCallsArrayState}  // Uses allCallsArrayState
                                    timetableTimings={dataState.timetableTimings}
                                    onLessonSelect={handleLessonSelect}
                                    selectedLesson={selectedLesson}
                                />
                            </div> 
        

                        </div>

                        <div className='oncall_reports_individual_lineChartDiv'>
                            <h1 className='oncall_reports_individual_divHeader'>Weekly Call Trends</h1>
                            <h1 className='oncall_reports_individual_divSubHeader'>Date is the Monday of that week</h1>
                            <div className='oncall_reports_individual_lineChartInnerDiv'>
                                <WeeklyCallTrendsLineChart data={allCallsArrayState} />
                            </div>
                        </div>




                        <div className='oncall_calls_main_div'>
                        <div className='oncall_calls_complete_calls_div'>
                            {filteredDataState.map((item) =>
                                <OnCallCompleteCall
                                    key={item["id"]}
                                    data={item}
                                    showPupilId={false}
                                    callTypes={callTypeColoursState}
                                    apiCall={apiCallRefresh}
                                />
                            )
                            }
                        </div>
                    </div>

                      
                    </div>
                    


                </div>  
                
            </div >

        )
    }
};