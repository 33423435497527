import React, { useState, useEffect } from 'react';
import { userAppData } from '../MainApp.js';
import './OnCallReports.css';
import { useNavigate } from "react-router-dom";
import LoadingComp from '../common/loading.js';
import { ApiError } from '../common/apiError.js';
import { SEC_ApiCall } from '../func.js';


export default function OncallReports() {
    const [dataState, setDataState] = useState([]);
    const [isLoadedState, setIsLoadedState] = useState(false);
    const [apiErrorState, setApiErrorState] = useState(false);
    const [apiErrorTextState, setApiErrorTextState] = useState('');

    const navigate = useNavigate();

    async function apiCall(params) {
        var url = 'oncall/reports';
        try {
            const response = await SEC_ApiCall(url, params, setApiErrorState);
            setDataState(response);
            setIsLoadedState(true);
        } catch (e) {
            setApiErrorState(true);
            setIsLoadedState(true);
            setApiErrorTextState(e);
        }
    };

    useEffect(() => {
        var params = {};
        apiCall(params);
    }, []);


    const handleGroupClick = (groupName) => {
        // Encode the groupName to ensure spaces are correctly represented in the URL
        const path = `/app/oncall/reports/group/${encodeURIComponent(groupName)}`;
        navigate(path);
    };

    const handleClassClick = (groupName) => {
        // Encode the groupName to ensure spaces are correctly represented in the URL
        const path = `/app/oncall/reports/class/${encodeURIComponent(groupName)}`;
        navigate(path);
    };


    if (!isLoadedState) {
        return (
            <LoadingComp />
        )
    } else if (apiErrorState) {
        return (
            <ApiError />
        )
    } else if (isLoadedState && !apiErrorState) {

        // Destructuring groups and classes from dataState for clarity
        const { groups, classes } = dataState;

        // Sort groups and classes in alphabetical/numerical order
        const sortedGroups = groups.sort();
        const sortedClasses = classes.sort();


        return (
            <div className='main_div'>
                <div className='internal_main_div'>
                    <div className='oncall_header_div'>
                        <h1 className='admin_header' >OnCall Reports</h1>
                    </div>
                    <div className='oncall_reports_main_div'>


                        <div className='oncall_reports_single_report_div'>
                            <div className='oncall_reports_single_report_name_div'>
                                Group Summary Report
                            </div>
                            <div className='oncall_reports_single_report_classes_list_div'>
                                {/* Mapping over sorted groups and rendering each group */}
                                {sortedGroups.map((group, groupIndex) => (
                                    <div
                                        key={groupIndex}
                                        className='oncall_reports_single_report_single_group_div'
                                        // Example onClick handler, replace with actual navigation or function
                                        onClick={() => handleGroupClick(group)}
                                    >
                                        {group}
                                    </div>
                                ))}
                                {/* Mapping over sorted classes and rendering each class */}
                                {sortedClasses.map((className, classIndex) => (
                                    <div
                                        key={classIndex}
                                        className='oncall_reports_single_report_single_class_div'
                                        // Example onClick handler, replace with actual navigation or function
                                        onClick={() => handleClassClick(className)}
                                    >
                                        {className}
                                    </div>
                                ))}
                            </div>
                        </div>


                    </div>
                </div>
            </div >

        )
    }
};