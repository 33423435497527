import React from 'react';
import './header.css';

export default function Header(props) {
    return (
        <div className='header_div'>
            <div className='requestTableHeaderItem1'>
                <h1 className='admin_header'>{props.text}</h1>
            </div>
        </div>
    )
};