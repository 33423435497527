
// calculates the weekly calls when given an array of every call, it cannot be given the pupils array it musy be an array where each item is the specific call containing submissionEpoch
export function calculateWeeklyCalls(records) {
    const startOfYear = new Date(new Date().getFullYear(), 8, 1);
    if (new Date() < startOfYear) startOfYear.setFullYear(startOfYear.getFullYear() - 1);

    const weeklyCalls = {};
    let weekStart = new Date(startOfYear);

    while (weekStart < new Date()) {
        const mondayDate = new Date(weekStart);
        mondayDate.setDate(mondayDate.getDate() - (mondayDate.getDay() - 1));
        const formattedDate = `${mondayDate.getDate().toString().padStart(2, '0')}/${(mondayDate.getMonth() + 1).toString().padStart(2, '0')}/${mondayDate.getFullYear()}`;
        weeklyCalls[formattedDate] = 0;  // Initialize count to 0
        weekStart.setDate(weekStart.getDate() + 7);  // Next week
    }

    records.forEach(record => {
        const callDate = new Date(record.submissionEpoch * 1000);
        callDate.setDate(callDate.getDate() - (callDate.getDay() - 1));  // Adjust to Monday
        const formattedDate = `${callDate.getDate().toString().padStart(2, '0')}/${(callDate.getMonth() + 1).toString().padStart(2, '0')}/${callDate.getFullYear()}`;
        weeklyCalls[formattedDate] = (weeklyCalls[formattedDate] || 0) + 1;
    });

    return weeklyCalls;
};


// finds the lesson/period name when given the epoch and the timetable timings from the system options table.
export function findSchoolPeriod(epoch, timetable) {

    // Convert the epoch to a Date object
    const date = new Date(epoch * 1000); // The epoch is in seconds, JavaScript Date expects milliseconds

    // Extract hours and minutes
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Convert time to minutes for easier comparison
    const timeInMinutes = hours * 60 + minutes;

    // Find the current period
    for (let i = 0; i < timetable.length; i++) {
        const periodStart = parseInt(timetable[i].start.split(':')[0]) * 60 + parseInt(timetable[i].start.split(':')[1]);
        const periodEnd = parseInt(timetable[i].end.split(':')[0]) * 60 + parseInt(timetable[i].end.split(':')[1]);

        if (timeInMinutes >= periodStart && timeInMinutes < periodEnd) {
            return timetable[i].period; // Returns the current period
        }
    }

    return "Outside school hours"; // If the time doesn't match any period
}
