import React, { useState, useEffect } from 'react';
import '../../App.css';
import './seInvntDeviceDesktop.css';
import './seInvntDeviceMobile.css';
import LoadingComp from '../common/loading.js';
import { ApiError } from '../common/apiError.js';
import { SEC_ApiCall } from '../func.js';
import { DeviceTextItem, DeviceProcessorItem } from './SeInvntDeviceComponents.js';
import { Chart } from 'chart.js';
import { ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

export default function SeInvntDevice() {
  const [dataState, setDataState] = useState([]);
  const [isLoadedState, setIsLoadedState] = useState(false);
  const [apiErrorState, setApiErrorState] = useState(null);
  const [apiErrorTextState, setApiErrorTextState] = useState('');

  const urlParams = new URLSearchParams(document.location.search.substring(1));
  const pcName = urlParams.get('name');
  const biosSerial = urlParams.get('serial');

  Chart.register(ArcElement, Tooltip, Legend);

  async function apiCall() {
    var params = {
      "name": pcName,
      "serial": biosSerial
    };
    var url = 'seinventory-v2/device';
    try {
      const response = await SEC_ApiCall(url, params, setApiErrorState);
      setDataState(response["Item"]);
      setIsLoadedState(true);
    } catch (e) {
      setIsLoadedState(true);
      setApiErrorState('error');
      setApiErrorTextState(e);
    }
  };

  useEffect(() => {
    apiCall();
  }, []);



  if (!isLoadedState) {
    return (
      <div className='main_div'>
        <LoadingComp />
      </div>
    )
  } else if (apiErrorState) {
    return (
      <ApiError />
    )
  } else {

    const drivePieChartData = {
      labels: ['Used Space', 'Free Space'],
      datasets: [
        {
          label: 'Drive Space',
          data: [dataState["driveUsedSpace"], dataState["driveFreeSpace"]],
          backgroundColor: [
            'rgba(255, 26, 26, 0.7)',
            'rgba(57, 230, 0, 0.7)',
          ],
          borderWidth: 0,
        },
      ],
    };

    const drivePieChartOptions = {
      responsive: false,
      plugins: {
        legend: {
          position: 'right',
          display: true,
        },
        title: {
          display: false,
          text: 'Chart.js Line Chart',
        },
        tooltips: {
          mode: 'index'
        },
        hover: {
          mode: 'index',
          intersect: false
        },
        maintainAspectRatio: false
      }
    };


    return (
      <div className='internal_main_div'>
        <div className='header_div'>
          <div className='requestTableHeaderItem1'>
            <h1 className='admin_header' >Device Details: {pcName}</h1>
            <p className='invnt_device_sync_time'>Device Sync Timestamp: {dataState["runDate"]} {dataState["runTime"]}</p>
          </div>
        </div>




        <div className='invnt_device_outer_block_div'>

          <div className='invnt_device_div_headers'>
            <h1 className='invnt_device_inner_block_header'>Operating System Information</h1>
          </div>

          <div className='invnt_device_inner_block_div'>

            <div className='invnt_device_col_div'>
              <DeviceTextItem title="Device Name" value={dataState["pcName"]} />
              <DeviceTextItem title="OS Caption" value={dataState["osCaption"]} />
              <DeviceTextItem title="Version" value={dataState["displayVersion"]} />
              <DeviceTextItem title="System Locale" value={dataState["systemLocale"]} />
            </div>

            <div className='invnt_device_col_div'>
              <DeviceTextItem title="Domain" value={dataState["domain"]} />
              <DeviceTextItem title="OS Release ID" value={dataState["osReleaseId"]} />
              <DeviceTextItem title="Version ID" value={dataState["osVersion"]} />
            </div>

          </div>

        </div>




        <div className='invnt_device_outer_block_div'>

          <div className='invnt_device_div_headers'>
            <h1 className='invnt_device_inner_block_header'>{dataState["driveName"]} - Drive Storage</h1>
          </div>



          <div className='invnt_device_inner_block_div'>

            <div className='invnt_device_col_div'>
              <DeviceTextItem title="Drive Formatting:" value={dataState["driveFormat"]} />
              <DeviceTextItem title="Total Size:" value={dataState["driveTotalSize"] + "GB"} />
              <DeviceTextItem title="Used Space:" value={dataState["driveUsedSpace"] + "GB"} />
              <DeviceTextItem title="Free Space:" value={dataState["driveFreeSpace"] + "GB"} />
            </div>

            <div className='invnt_device_col_div'>
              <div className='pie_chart_div'>
                <Doughnut data={drivePieChartData} options={drivePieChartOptions} />
              </div>

            </div>

          </div>

        </div>





        <div className='invnt_device_outer_block_div'>

          <div className='invnt_device_div_headers'>
            <h1 className='invnt_device_inner_block_header'>Hardware Information</h1>
          </div>

          <div className='invnt_device_inner_block_div'>

            <div className='invnt_device_col_div'>
              <DeviceTextItem title="Memory" value={dataState["totalMemory"] + "GB"} />
              <DeviceProcessorItem data={dataState["processors"]} />
            </div>

            <div className='invnt_device_col_div'>
              <DeviceTextItem title="Manufacturer" value={dataState["systemManufacturer"]} />
              <DeviceTextItem title="Model" value={dataState["systemModel"]} />
              <DeviceTextItem title="BIOS Serial" value={dataState["biosSerial"]} />
              <DeviceTextItem title="BIOS Version" value={dataState["biosVersion"]} />
            </div>

          </div>
        </div>


      </div>

    )

  }
};


