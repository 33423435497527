import React, { useState, useEffect } from 'react';
import '../../App.css';
import { RequestsTable } from '../common/RequestsTable';
import { userAppData } from '../MainApp.js';
import reloadIcon from '../assets/35679.png';
import { SEC_ApiCall } from '../func.js';
import { ApiError } from '../common/apiError.js';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';


export default function ItRequests() {
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(document.location.search.substring(1));
  let selectUrlValue = urlParams.get('select');

  const [dataState, setDataState] = useState([]);
  const [isLoadedState, setIsLoadedState] = useState(false);
  const [apiErrorState, setApiErrorState] = useState(null);
  const [apiErrorTextState, setApiErrorTextState] = useState('');
  const [selectorValueState, setSelectorValueState] = useState(selectUrlValue);

  const [resultsPerPageState, setResultsPerPageState] = useState(10);
  const [currentPageState, setCurrentPageState] = useState(1);
  const [numberOfPagesState, setNumberOfPagesState] = useState(1);
  const [pageDataState, setPageDataState] = useState(null);

  function paginate(array, page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  async function apiCall(urlQuery) {
    var url = 'it-requests';
    var params = {
      'query': urlQuery
    };
    try {
      const response = await SEC_ApiCall(url, params, setApiErrorState);
      setDataState(response);
      let resultsSize = response.length;
      setNumberOfPagesState(Math.ceil((resultsSize / resultsPerPageState)));
      let pageData = paginate(response, resultsPerPageState, currentPageState)
      setPageDataState(pageData);
      setIsLoadedState(true);
    } catch (e) {
      setIsLoadedState(true);
      setApiErrorState('error');
      setApiErrorTextState(e);
    }
  };

  useEffect(() => {
    if (selectUrlValue === null) {
      selectUrlValue = 'incomplete';
      navigate('/app/ItSupport/requests?select=incomplete')
      apiCall(selectUrlValue);
    }
    else {
      apiCall(selectUrlValue);
    }
  }, []);

  const handleChange = (e) => {
    var querySelect = e.target.value;
    let newUrl = '/app/ItSupport/requests?select=' + querySelect;
    navigate(newUrl)
    setSelectorValueState(querySelect);
    apiCall(querySelect);
  };

  function dataReload() {
    apiCall(selectorValueState);
  };

  let headerArray = null;
  let requestSelectArray = null;
  if (userAppData["itUser"] == 3) { // if user is IT manager
    headerArray = [{ id: '1', class: 'requestTableNoCollapse', name: 'Request ID' }, { id: '2', class: 'requestTableNoCollapse', name: 'Request Title' }, { id: '3', class: 'requestTableCollapse', name: 'Submitting User' }, { id: '4', class: 'requestTableCollapse', name: 'Assigned User' }, { id: '5', class: 'requestTableNoCollapse', name: 'Required Date' }];
    requestSelectArray = [{ id: '1', value: 'incomplete', text: 'Incomplete (All)' }, { id: '2', value: 'complete', text: 'Complete (All)' }, { id: '3', value: 'incompleteOwn', text: 'Incomplete (Own)' }, { id: '4', value: 'completeOwn', text: 'Complete (Own)' }, { id: '5', value: 'all', text: 'All' }];
  } else if (userAppData["itUser"] == 2) { // if user is IT Technician
    headerArray = [{ id: '1', class: 'requestTableNoCollapse', name: 'Request ID' }, { id: '2', class: 'requestTableNoCollapse', name: 'Request Title' }, { id: '3', class: 'requestTableCollapse', name: 'Submitting User' }, { id: '4', class: 'requestTableNoCollapse', name: 'Required Date' }];
    requestSelectArray = [{ id: '1', value: 'incomplete', text: 'Incomplete' }, { id: '2', value: 'complete', text: 'Complete' }];
  } else { // if user is IT user or reporter
    headerArray = [{ id: '1', class: 'requestTableNoCollapse', name: 'Request ID' }, { id: '2', class: 'requestTableNoCollapse', name: 'Request Title' }, { id: '3', class: 'requestTableCollapse', name: 'Assigned User' }, { id: '4', class: 'requestTableNoCollapse', name: 'Required Date' }];
    requestSelectArray = [{ id: '1', value: 'incomplete', text: 'Incomplete' }, { id: '2', value: 'complete', text: 'Complete' }];
  }

  const selectRows = requestSelectArray.map(item => {
    return (
      <option key={item.id} value={item.value}>{item.text}</option>
    )
  });

  if (apiErrorState === 'error') {
    return (
      <ApiError />
    )
  } else {

    function onResultsSelectorChange(event) {
      var querySelect = event.target.value;
      setResultsPerPageState(querySelect);
      setCurrentPageState(1);
      let resultsSize = dataState.length;
      setNumberOfPagesState(Math.ceil((resultsSize / querySelect)));
      let pageData = paginate(dataState, querySelect, 1)
      setPageDataState(pageData);
    }

    function nextPage() {
      let currentPage = currentPageState;
      if (currentPage >= numberOfPagesState) {
        setCurrentPageState(currentPage);
      } else {
        let nextPage = currentPage + 1;
        setCurrentPageState(nextPage);
        let pageData = paginate(dataState, resultsPerPageState, nextPage)
        setPageDataState(pageData);
      }
    }

    function prevPage() {
      let currentPage = currentPageState;
      if (currentPage > 1) {
        let nextPage = currentPage - 1;
        setCurrentPageState(nextPage);
        let pageData = paginate(dataState, resultsPerPageState, nextPage)
        setPageDataState(pageData);
      } else {
        setCurrentPageState(1);
      }
    }


    return (
      <div className='internal_main_div'>
        <div className='header_div'>

          <div className='requestTableHeaderItem1'>
            <h1 className='admin_header' >IT Requests</h1>
          </div>

          <div className='requestTableHeaderItem2'>
            <select className='requestSelector' id='requestSelector' name="category" onChange={handleChange} defaultValue={selectorValueState}>{selectRows}</select>
            <button className='requestTableHeaderButton' type='button' onClick={dataReload}><img className='requestTableHeaderIcon' src={reloadIcon} alt='Reload' /></button>
          </div>

          <div className='requestTableHeaderItem3'>
            <span className="requestsPerPageLabel">Requests per page</span>
            <select className='resultsPerPageSelector' defaultValue="10" onChange={onResultsSelectorChange}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>



        </div>

        <RequestsTable data={pageDataState} headers={headerArray} apiError={apiErrorState} isLoaded={isLoadedState} headerColour='#f9b233' url="/app/ItSupport/requests/srv?id=" />
        <div className="nextRequestPageDiv">
          <div className="pageNumberButtons" onClick={prevPage}><FaArrowLeft color="#fb9233" /></div><div className="pageNumberDiv"><input className="pageNumberInput" type="text" defaultValue={currentPageState} /> / {numberOfPagesState}</div><div className="pageNumberButtons" onClick={nextPage}><FaArrowRight color="#fb9233" /></div>
        </div>



      </div>
    )
  }
};
