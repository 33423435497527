import React from 'react';
import { userAppData } from '../MainApp.js';
import { Outlet, useNavigate } from "react-router-dom";
import './OncallDesktop.css';



export default function Oncall() {
    let navigate = useNavigate();
    const oncallUser = userAppData["onCall"]['perms'];

    if (oncallUser == 0) {
        navigate("/app");
    }


    return (
        <div className='main_div'>
            <Outlet />
            
        </div>
    )
};