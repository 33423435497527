import React, { useState, useEffect } from 'react';
import { userAppData } from '../MainApp.js';
import LoadingComp from '../common/loading.js';
import { ApiError } from '../common/apiError.js';
import {
  SEC_ApiCall,
  SEC_DataApiPost,
  fileDownload,
  fileDownloadAll
} from '../func.js';
import {
  Srv_Toolbar,
  Srv_Header,
  Srv_BasicItem,
  Srv_SelectorItem,
  Srv_Files,
  Srv_EventsComp,
  Srv_CompNotes
} from '../common/srvComponents.js';
import { DataUpdateModal } from '../common/modals.js';

export default function ItSrv() {
  // data and page loading states
  const [dataState, setDataState] = useState([]);
  const [requestIdState, setRequestIdState] = useState(null);
  const [isLoadedState, setIsLoadedState] = useState(false);
  const [apiErrorState, setApiErrorState] = useState(false);
  const [apiErrorDataState, setApiErrorDataState] = useState('');
  // editable field states
  const [completedState, setCompletedState] = useState(null);
  const [assignedUserState, setAssignedUserState] = useState(null);
  const [newEventState, setNewEventState] = useState(null);
  const [newEventHasErrorState, setNewEventHasErrorState] = useState(false);
  const [newEventErrorTextState, setNewEventErrorTextState] = useState('');
  const [completionNotesHasErrorState, setCompletionNotesHasErrorState] = useState(false);
  const [completionNotesErrorTextState, setCompletionNotesErrorTextState] = useState('');
  const [completionNotesState, setCompletionNotesState] = useState('');
  // saving states
  const [displaySaveMessageState, setDisplaySaveMessageState] = useState(false);
  const [saveDivClassState, setSaveDivClassState] = useState('saveMessageDivFadeIn');
  const [displayErrorMessageState, setDisplayErrorMessageState] = useState(false);
  const [errorDivClassState, setErrorDivClassState] = useState('errorMessageDivFadeIn');
  const [submissionErrorState, setSubmissionErrorState] = useState(false);
  const [submissionErrorDataState, setSubmissionErrorDataState] = useState(null);
  // css states
  const [srvToolbarRightDivState, setSrvToolbarRightDivState] = useState('srvToolbarRightDiv');
  // modal appearence state
  const [modalActiveState, setModalActiveState] = useState(false);
  const [modalApiState, setModalApiState] = useState('loading'); // used for the modals content state, must be loading, error or success, is passed to the API function to manage during submission
  
  function handleChangeCompleted(event) {
    var querySelect = event.target.value;
    setCompletedState(querySelect);
  }

  function handleChangeAssignedUser(event) {
    var querySelect = event.target.value;
    setAssignedUserState(querySelect);
  }


  function handleChangeNewEvent(event) {
    let descriptionStr = event.target.value;
    if (descriptionStr.length >= 200) {
      setNewEventHasErrorState(true);
      setNewEventErrorTextState('New event text must be 200 characters or less')
    } else {
      setNewEventHasErrorState(false);
      setNewEventErrorTextState('')
      setNewEventState(event.target.value)
    }
  };

  function handleChangeCompletionNotes(event) {
    let notesStr = event.target.value;
    if (notesStr.length >= 500) {
      setCompletionNotesHasErrorState(true);
      setCompletionNotesErrorTextState('Completion notes must be 500 characters or less');
    } else {
      setCompletionNotesHasErrorState(false);
      setCompletionNotesErrorTextState('');
      setCompletionNotesState(event.target.value);
    }
  };

  async function apiCall(id) {
    var url = ' it-requests/single-it-request?requestId=' + id;
    var params = {};
    try {
      const response = await SEC_ApiCall(url, params, setApiErrorState)
      const json = JSON.parse(response['body']);
      setDataState(json);
      setRequestIdState(json['requestData'][0]["requestId"])
      setAssignedUserState(json['requestData'][0]["assignedUser"])
      setCompletedState(json['requestData'][0]["requestCompleted"])
      setCompletionNotesState(json['requestData'][0]["completionNotes"])
      setIsLoadedState(true);
    } catch (e) {
      setIsLoadedState(true);
      setApiErrorState(true);
      setApiErrorDataState(e);
    }
  };

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const id = params.get('id');
    setRequestIdState(id);
    if (id) {
      apiCall(id);
    } else {
      setIsLoadedState(true);
      setApiErrorState(true);
    }

  }, []);

  async function handleSave(event) {
    
    let newEvent = {};
    if (newEventState) { // if description has been entered into text area
      newEvent["user"] = userAppData['cogId'];
      newEvent["timestamp"] = '';
      newEvent["eventData"] = newEventState;
    };

    let formObj = {
      'id': requestIdState,
      'completed': completedState,
      'date': '',
      'time': '',
      'event': newEvent,
    };

    if (userAppData['itUser'] == '2' || userAppData['itUser'] == '3') {
      formObj["assignedUser"] = assignedUserState;
      formObj["completionNotes"] = completionNotesState;
    };

    var formData = JSON.stringify(formObj);
    const url = 'it-requests/single-it-request';
    let params = {};
    try {
      const response = await SEC_DataApiPost(url, formData, params);
      if (response['data'] === "error" || response['message'] === 'Internal server error') {
        setSubmissionErrorState(true);
        setSubmissionErrorDataState(response['data']);
        setModalActiveState(true);
        setModalApiState('error');
      } else {
        setNewEventState(null);
        setCompletionNotesState('');
        setModalActiveState(true);
        setModalApiState('success');
      }
    } catch (e) {
      setSubmissionErrorState(true);
      setSubmissionErrorDataState(e);
      setModalActiveState(true);
      setModalApiState('error');
    }
  };

  if (!isLoadedState) {
    return (
      <div className='main_div'>
        <LoadingComp />
      </div>
    )
  } else if (apiErrorState) {
    return (
      <ApiError />
    )
  } else {
    // get list of potential assigned users and replace the keys in the arrays
    let itUsersObject = dataState['itUsers'];
    // create object for completed selector
    let completedSelectorObject = [
      { 'value': '0', 'displayText': 'Incomplete' },
      { 'value': '1', 'displayText': 'Complete' }
    ];

    let itUserPerms = userAppData["itUser"];
    let isItAdmin = null;
    if (itUserPerms == '1' || itUserPerms == '4') {
      isItAdmin = false;
    } else if (itUserPerms == '2' || itUserPerms == '3') {
      isItAdmin = true;
    } else {
      return (
        <ApiError />
      )
    }
    let filesObj = null;
    let filesData = null;
    let dataContainsFiles = null;
    if (dataState['requestData'][0]["fileNames"] == 'null') {
      dataContainsFiles = false;
    } else {
      dataContainsFiles = true;
      filesData = dataState['requestData'][0]["fileNames"];
      filesObj = JSON.parse(filesData);
    }
    let primaryEvent = dataState['requestData'][0]["requestEvents"]["primaryEvent"];
    let additionalEventsObj = dataState['requestData'][0]["requestEvents"]["additionalEvents"];

    // let replacedData = descriptionData.replace(/'/g, '"');
    // let descriptionObj = JSON.parse(descriptionData);

    let requestComplete = null;
    let itUserCompletedText = null;
    if (dataState['requestData'][0]["requestCompleted"] == 0) {
      requestComplete = false;
      itUserCompletedText = 'Incomplete';
    } else {
      requestComplete = true;
      itUserCompletedText = 'Complete';
    }

    function modalClose() {
      if (modalApiState === 'success') {
        // reset new event field
        document.getElementById("newEventTextArea").value = ""; // resets the form, only required to reset the new event text area
        setModalActiveState(false);
        setModalApiState('loading');
        // reload data
        apiCall(requestIdState);
      } else {
        setModalActiveState(false);
        setModalApiState('loading');
      }
    };

    return (
      <div className='internal_main_div'>

        <DataUpdateModal modalActiveState={modalActiveState} setStateFunction={setModalActiveState} requestType='IT-SRV' apiState={modalApiState} onModalClose={modalClose} />

        <Srv_Toolbar saveMessageState={displaySaveMessageState} errorMessageState={displayErrorMessageState} saveDivState={saveDivClassState} errorDivState={errorDivClassState} rightDivState={srvToolbarRightDivState} saveFunc={handleSave} />

        <Srv_Header text='IT Request ID' value={requestIdState} />

        <div className="srv_flex-container">
          <div className="srv_leftDiv">

            <Srv_BasicItem title='Request Title' data={dataState['requestData'][0]["requestTitle"]} />

            <Srv_BasicItem title='Required Date' data={dataState['requestData'][0]["requiredDate"]} />

            {isItAdmin ? <Srv_SelectorItem title='Currently Assigned User' data={itUsersObject} onChangeFunction={handleChangeAssignedUser} defValue={dataState['requestData'][0]["assignedUser"]} /> :
              <Srv_BasicItem title='Currently Assigned User' data={dataState['requestData'][0]["assignedUser"]} />}

            {isItAdmin ? <Srv_SelectorItem title='Request Complete' data={completedSelectorObject} onChangeFunction={handleChangeCompleted} defValue={completedState} /> :
              <Srv_BasicItem title='Request Complete' data={dataState['requestData'][0]["completed"]} />}


            <Srv_BasicItem title='Submission Date & Time' data={dataState['requestData'][0]["requestSubmissionDate"] + dataState['requestData'][0]["requestSubmissionTime"]} />

            <Srv_BasicItem title='Request Location' data={dataState['requestData'][0]["location"]} />

            <Srv_BasicItem title='Submitting User' data={dataState['requestData'][0]["submittingUser"]} />


            {dataContainsFiles ? <Srv_Files data={filesObj} filesData={filesData} /> : null}



            {isItAdmin ? <Srv_CompNotes errorState={completionNotesHasErrorState} compNotesState={completionNotesState} changeNotesFunc={handleChangeCompletionNotes} /> : null}

          </div>

          <Srv_EventsComp newEventHasErrorState={newEventHasErrorState} newEventErrorTextState={newEventErrorTextState} primaryEvent={primaryEvent} additionalEvents={additionalEventsObj} handleChangeNewEvent={handleChangeNewEvent} />




        </div>

      </div >
    )
  }
};
