import React, { useState, useEffect } from 'react';
import '../../App.css';
import './SeInvntDeviceComponentsDesktop.css';
import LoadingComp from '../common/loading.js';
import { ApiError } from '../common/apiError.js';
import { SEC_ApiCall } from '../func.js';

export function DeviceTextItem(props) {
  return (
    <div>
      <p>{props.title} - {props.value}</p>
    </div>
  )
};

export function DeviceProcessorItem(props) {
  var dataObj = JSON.parse(props.data);
  return (
    <div className='processor_outer_div'>
      {dataObj.map((item, index) =>
        <div key={index}>
          <p className='processor_heading'>Processor - {item.DeviceID}</p>
          <p className='processor_item'>Name: {item.Name}</p>
          <p className='processor_item'>Manufacturer: {item.Manufacturer}</p>
          <p className='processor_item'>Number of Cores: {item.NumberOfCores}</p>
        </div>
      )
      }
    </div>
  )

};


