import React, { useState, useEffect } from 'react';
import { userAppData } from '../MainApp.js';
import './OnCallCompleteCall.css';
import { epochToReadable, SEC_DataApiPost } from '../func.js';
import { IoPersonAdd } from "react-icons/io5";
import { MdOutlineNoteAdd } from "react-icons/md";
import BasicSingleLineTextEntry from '../common/formComponents/BasicSingleLineTextEntry.js';



export function OnCallCompleteCall(props) {
    const [callUpdatingState, setCallUpdatiingState] = useState(false);
    const [callUpdatingErrorState, setCallUpdatiingErrorState] = useState(false);
    const [postApiErrorState, setPostApiErrorState] = useState(false);
    const [notesArrayState, setNotesArrayState] = useState(null);
    const [viewAddNoteState, setViewAddNoteState] = useState(false);
    const [assignedUserState, setAssignedUserState] = useState('');

    // Add note states
    const [newNoteApiState, setNewNoteApiState] = useState(false);
    const [newNoteTextState, setNewNoteTextState] = useState('');
    const [newNoteTextCharacterCountState, setNewNoteTextCharacterCountState] = useState(0);
    const [newNoteTextHasErrorState, setNewNoteTextHasErrorState] = useState(false);
    const [newNoteTextErrorTextState, setNewNoteTextErrorTextState] = useState(false);

    useEffect(() => {
        // This checks if props.data['assignedUser'] is not null and not undefined.
        if (props.data['assignedUser'] != 'null') {
            setAssignedUserState(props.data['assignedUser']);
        } else {
            // If props.data['assignedUser'] is null or undefined, set 'None'
            setAssignedUserState('None');
        }
        setNotesArrayState(props.data['notes']);
    }, [props.data]);

    const findTypeColour = (typeName) => {
        const type = props.callTypes.find(type => type.typeName === typeName);
        return type ? type.colourCode : '#f2f2f2'; // Replace 'defaultColour' with a default colour of your choice
    };

    const callTypeColour = findTypeColour(props.data['callType']);

    function postApiError() {
        setPostApiErrorState(true);
        setCallUpdatiingState(false);
        setCallUpdatiingErrorState(true);
    }

    async function saveNote() {
        setCallUpdatiingErrorState(false);
        setCallUpdatiingState(true);
        var params = {};
        let formData = {
            "id": props.data['id'],
            "note": newNoteTextState
        };
        let url = 'oncall/updateCall/notes';
        try {
            const response = await SEC_DataApiPost(url, formData, formData, postApiError);
            setCallUpdatiingState(false);
            var params = {};
            props.apiCall(params);
            setViewAddNoteState(false);
        } catch (e) {
            console.log(e);
            var params = {};
            props.apiCall(params);
            setCallUpdatiingState(false);
            setCallUpdatiingErrorState(true);
        }

    };

    function actionButtonClicked() {
        if (viewAddNoteState) {
            setViewAddNoteState(false);
        } else {
            setViewAddNoteState(true);
        }
    };



    return (
        <div className='oncall_completeCall' >
            <div className='oncall_activeCallFirstRow' style={{ backgroundColor: callTypeColour }} >
                <div className='oncall_activeCallIdRow' >
                    <p className='oncall_activeCall_id'>ID: {props.data['id']}</p>
                </div>
                <div className='oncall_activeCallIdRow' >
                    {props.showPupilId ? (<p className='oncall_completeCall_text'>{props.data['pupilId']}</p>) : null}
                    <p className='oncall_completeCall_text'>{props.data['callType']}</p>
                    <p className='oncall_completeCall_text'>{epochToReadable(props.data['submissionEpoch'])}</p>
                </div>
            </div>

            <div className='oncall_completeCallSecondRow' >
                <p className='oncall_completeCall_SecondRowText'>Location: {props.data['location']}</p>
                <p className='oncall_completeCall_SecondRowText'>Staff: {props.data['loggingUser']}</p>
            </div>
            <p className='oncall_completeCall_description'>Assigned: {assignedUserState}</p>
            <p className='oncall_completeCall_description'>Description: {props.data['description']}</p>
            {notesArrayState && notesArrayState.length > 0 ? (
                <div className='oncall_completeCall_notes_div'>
                    <p className='oncall_completeCall_description'>Notes:</p>
                    {notesArrayState.map((item) =>
                        <div key={item['dateTime'] + item['user']} className='oncall_completeCall_notes_inner_div'>
                            <div className='oncall_completeCall_notes_header_div'>
                                <p className='oncall_completeCall_notes_header_text'>{item['user']}</p>
                                <p className='oncall_completeCall_notes_header_text'>{epochToReadable(item['dateTime'])}</p>
                            </div>
                            <div className='oncall_completeCall_notes_text_div'>
                                <p className='oncall_completeCall_notes_text'>{item['note']}</p>
                            </div>
                        </div>
                    )
                    }
                </div>
            ) : null}

            <div className='oncall_completeCallActionsRow'>

                {viewAddNoteState ? (
                    <div className='oncall_completeCall_NewNoteDiv'>
                        <BasicSingleLineTextEntry
                            display='col'
                            title='Note'
                            data={newNoteTextState}
                            setData={setNewNoteTextState}
                            nullAllowed={true}
                            displayCounter={true}
                            maxCharacters='100'
                            onError={newNoteTextHasErrorState}
                            onErrorSet={setNewNoteTextHasErrorState}
                            errorText={newNoteTextErrorTextState}
                            errorTextSet={setNewNoteTextErrorTextState}
                            characterCount={newNoteTextCharacterCountState}
                            setCharacterCount={setNewNoteTextCharacterCountState}
                        />
                        <div className='oncall_newNote_submit' onClick={() => saveNote()} style={{ opacity: newNoteApiState ? 0.5 : 1, pointerEvents: newNoteApiState ? 'none' : 'auto' }}>
                            <p className='oncall_newNote_submit_button_text'>Save Note</p>
                        </div>

                    </div>
                ) : null}





                <div className='oncall_completeCallAddNoteButton' onClick={() => actionButtonClicked('note')} style={{ opacity: callUpdatingState ? 0.3 : 1, pointerEvents: callUpdatingState ? 'none' : 'auto' }}><MdOutlineNoteAdd style={{ color: '#ffffff', fontSize: '40px' }} /></div>




            </div>

            {callUpdatingState ? (<div className='oncall_completeCallUpdatingRow' >
                <div>Call Updating...</div>
            </div>) : null}

            {callUpdatingErrorState ? (<div className='oncall_completeCallUpdatingErrorRow' >
                <div>Call Update Error</div>
            </div>) : null}

        </div>
    )

};