import React from 'react';





export default function ReproRequests() {
    return (
      <div  className='main_div'>
        <h2>Repro Requests</h2>
      </div>
    );
  };

