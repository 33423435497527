import './BasicSingleLineTextEntry.css';
import React, { useState, useEffect } from 'react';

const errorBorderStyle = {
    'width': '100%',
    'border': '3px solid red',
    'borderRadius': '6px',
    'verticalAlign': 'middle',
    'fontSize': '18px',
    'fontFamily': 'Verdana, Geneva, sans-serif',
    'backgroundColor': '#f2f2f2',
    'color': '#666666',
};
const normalBorderStyle = {
    'width': '100%',
    'border': '0px solid #cccccc',
    'borderRadius': '6px',
    'verticalAlign': 'middle',
    'fontSize': '18px',
    'fontFamily': 'Verdana, Geneva, sans-serif',
    'backgroundColor': '#f2f2f2',
    'color': '#666666',
};

export default function BasicSingleLineTextEntry(props) {
    const [displayValueCss, setDisplayValueCssState] = useState('bslteInputDivRow');
    const [displayInputValueCss, setDisplayInputValueCssState] = useState('bslteSingleTextInputRow');

    useEffect(() => {
        if (props.display == 'col') {
            setDisplayValueCssState('bslteInputDivCol');
            setDisplayInputValueCssState('bslteSingleTextInputCol');
        } 
    }, []);



    function handleValueChange(event) {
        let titleStr = event.target.value;
        props.setCharacterCount(titleStr.length);
        if (props.characterCount >= props.maxCharacters) {
            props.setData(event.target.value)
            props.onErrorSet(true);
            let text = props.title + " must be " + props.maxCharacters + " characters or less";
            props.errorTextSet(text);
        } else if (event.target.value == '' && props.nullAllowed == false) {
            props.onErrorSet(true);
            props.setData('');
            let text = props.title + " cannot be blank";
            props.errorTextSet(text);
        } else {
            props.onErrorSet(false);
            props.errorTextSet('');
            props.setData(event.target.value)
        }


    }

    return (
        <div className='bslteFormRow'>
            <div className={displayValueCss}>
                <input value={props.data} style={props.onError ? errorBorderStyle : normalBorderStyle} className={displayInputValueCss} type="text" onChange={handleValueChange} />{props.displayCounter ? <label className='bslteCounterLabel'>{props.characterCount}/{props.maxCharacters}</label> : null}<label className='bslteErrorLabel'>{props.errorText}</label>
            </div>
        </div>
    )
};