import React, { useState } from 'react';
import { userAppData } from '../MainApp.js';
import { SubmitButton } from '../common/formComponents.js';
import { StandardSingleLineTextEntry } from '../common/formComponents/StandardSingleLineTextEntry.js';
import { StandardMultiLineTextEntry } from '../common/formComponents/StandardMultiLineTextEntry.js';
import { StandardDateSelector } from '../common/formComponents/StandardDateSelector.js';
import { SEC_DataApiPost } from '../func.js';
import { RequestSubmittedModal } from '../common/modals.js';



export default function MaintenanceForm() {
  // data states for the form fields
  // request title
  const [requestTitleState, setRequestTitleState] = useState('');
  const [requestTitleCharacterCountState, setRequestTitleCharacterCountState] = useState(0);
  const [requestTitleHasErrorState, setRequestTitleHasErrorState] = useState(false);
  const [requestTitleErrorTextState, setRequestTitleErrorTextState] = useState('');
  // request location
  const [requestLocationState, setRequestLocationState] = useState('');
  const [requestLocationCharacterCountState, setRequestLocationCharacterCountState] = useState(0);
  const [requestLocationHasErrorState, setRequestLocationHasErrorState] = useState(false);
  const [requestLocationErrorTextState, setRequestLocationErrorTextState] = useState(false);
  // request description
  const [requestDescriptionState, setRequestDescriptionState] = useState('');
  const [requestDescriptionCharacterCountState, setRequestDescriptionCharacterCountState] = useState(0);
  const [requestDescriptionHasErrorState, setRequestDescriptionHasErrorState] = useState(false);
  const [requestDescriptionErrorTextState, setRequestDescriptionErrorTextState] = useState(false);
  // required date
  const [requiredDateState, setRequiredDateState] = useState('0000-00-00');
  const [requiredDateHasErrorState, setRequiredDateHasErrorState] = useState(false);
  const [requiredDateErrorTextState, setRequiredDateErrorTextState] = useState(false);
  // modal appearence state
  const [modalActiveState, setModalActiveState] = useState(false);
  // Submissions states
  const [modalApiState, setModalApiState] = useState('loading'); // used for the modals content state, must be loading, error or success, is passed to the API function to manage during submission
  const [requestSuccessRid, setRequestSuccessRid] = useState(null); // stores the request id value when the request is successful

  async function handleSubmit(event) {
    event.preventDefault(); // This stops the entire page reloading on form submission (the default thing form submissions do)
    setModalActiveState(true);
    setModalApiState('loading');

    // check for blanks in form fields if needed
    let requestTitleIsBlank = false;
    let requestLocationIsBlank = false;
    let requestDescriptionIsBlank = false;
    let requiredDateIsBlank = false;

    if (requestTitleState === '') {
      requestTitleIsBlank = true;
      setRequestTitleHasErrorState(true);
      setModalActiveState(false);
      setRequestTitleErrorTextState('Request title cannot be blank');
    }
    if (requestLocationState === '') {
      requestLocationIsBlank = true;
      setModalActiveState(false);
      setRequestLocationHasErrorState(true);
      setRequestLocationErrorTextState('Request location cannot be blank')
    }
    if (requestDescriptionState === '') {
      requestDescriptionIsBlank = true;
      setModalActiveState(false);
      setRequestDescriptionHasErrorState(true);
      setRequestDescriptionErrorTextState('Request description cannot be blank')
    }
    if (requiredDateState === '0000-00-00') {
      requiredDateIsBlank = true;
      if (requiredDateErrorTextState != 'Required date cannot be in the past') {
        setModalActiveState(false);
        setRequiredDateHasErrorState(true);
        setRequiredDateErrorTextState('Required date cannot be blank');
      }
    }
    if (requestTitleHasErrorState || requestLocationHasErrorState || requestDescriptionHasErrorState || requiredDateHasErrorState || requestTitleIsBlank || requestLocationIsBlank || requestDescriptionIsBlank || requiredDateIsBlank) {
      setModalActiveState(false);
    } else { // continue submission if there are no errors
      // create event array and object
      let eventObject = {
        "primaryEvent": {
          "user": userAppData['cogId'],
          "timestamp": '',
          "eventData": requestDescriptionState
        },
        "additionalEvents": []
      };
      // add all data to formData
      let formObj = {
        "title": requestTitleState,
        "location": requestLocationState,
        "events": eventObject,
        "requiredDate": requiredDateState,
        "fileNames": null,
      };

      var formData = JSON.stringify(formObj);
      var url = 'maintenance';
      var params = {}
      try {
        const response = await SEC_DataApiPost(url, formData, params, setModalApiState)
        let rid = response["data"];
        if (rid == "error") {
          setModalActiveState(true);
          setModalApiState('error');
        } else {
          setModalActiveState(true);
          setRequestSuccessRid(rid);
          setModalApiState('success');
        }
      } catch (e) {
        setModalActiveState(true);
        setModalApiState('error');
        console.log(e);
      }
    };
  };

  function modalClose() {
    if (modalApiState === 'success') {
      // reset all form entries
      setRequestTitleState('');
      setRequestTitleHasErrorState(false);
      setRequestTitleErrorTextState('');
      setRequestLocationState('');
      setRequestLocationHasErrorState(false);
      setRequestLocationErrorTextState(false);
      setRequestDescriptionState('');
      setRequestDescriptionHasErrorState(false);
      setRequestDescriptionErrorTextState(false);
      setRequiredDateState('0000-00-00');
      setRequiredDateHasErrorState(false);
      setRequiredDateErrorTextState(false);
      setModalApiState('loading');
      setRequestSuccessRid(null);
      setModalActiveState(false);
      setModalApiState('loading');
      setRequestTitleCharacterCountState(0);
      setRequestLocationCharacterCountState(0);
      setRequestDescriptionCharacterCountState(0);
      document.getElementById("maintenanceForm").reset(); // resets the form, only required to reset the date selector
    } else {
      setModalActiveState(false);
      setModalApiState('loading');
    }
  };

  return (
    <div className='internal_main_div'>
      <div className='header_div'>
        <h1 className='admin_header'>Maintenance Request Form</h1>
      </div>
      <div className='formDiv'>
        <form onSubmit={handleSubmit} id='maintenanceForm'>
          <RequestSubmittedModal modalActiveState={modalActiveState} setStateFunction={setModalActiveState} requestType='Maintenance' rid={requestSuccessRid} apiState={modalApiState} onModalClose={modalClose} />

          <StandardSingleLineTextEntry title='Request Title' data={requestTitleState} setData={setRequestTitleState} nullAllowed={false} displayCounter={true} maxCharacters='30' onError={requestTitleHasErrorState} onErrorSet={setRequestTitleHasErrorState} errorText={requestTitleErrorTextState} errorTextSet={setRequestTitleErrorTextState} characterCount={requestTitleCharacterCountState} setCharacterCount= {setRequestTitleCharacterCountState} />

          <StandardSingleLineTextEntry title='Location' data={requestLocationState} setData={setRequestLocationState} nullAllowed={false} displayCounter={true} maxCharacters='30' onError={requestLocationHasErrorState} onErrorSet={setRequestLocationHasErrorState} errorText={requestLocationErrorTextState} errorTextSet={setRequestLocationErrorTextState} characterCount={requestLocationCharacterCountState} setCharacterCount= {setRequestLocationCharacterCountState} />

          <StandardMultiLineTextEntry title='Description' data={requestDescriptionState} setData={setRequestDescriptionState} nullAllowed={false} displayCounter={true} maxCharacters='200' onError={requestDescriptionHasErrorState} onErrorSet={setRequestDescriptionHasErrorState} errorText={requestDescriptionErrorTextState} errorTextSet={setRequestDescriptionErrorTextState} characterCount={requestDescriptionCharacterCountState} setCharacterCount= {setRequestDescriptionCharacterCountState}/>

          <StandardDateSelector title='Required Date' setData={setRequiredDateState} onError={requiredDateHasErrorState} onErrorSet={setRequiredDateHasErrorState} errorText={requiredDateErrorTextState} errorTextSet={setRequiredDateErrorTextState} />

          <SubmitButton text='Submit Request' />
        </form>
      </div>
    </div>

  )

};