import React from 'react';
import { userAppData } from '../MainApp.js';
import ItSrv from './ItRequestSingleView.js';
import ItRequests from './ItRequests.js';
import { ItSupportForm } from './ItSupportForm.js';
import { Outlet, useNavigate } from "react-router-dom";
import './itSupport.css';



export default function ItSupport() {
    let navigate = useNavigate();
    const itUser = userAppData["itUser"];

    if (itUser == 0) {
        navigate("/app");
    }

    let viewForm = false;
    let viewRequests = false;
    let viewReports = false;

    if (itUser == 1 || itUser == 2) {
        viewForm = true;
        viewRequests = true;
    } else if (itUser == 3 || itUser == 4) {
        viewForm = true;
        viewRequests = true;
        viewReports = true;
    } else {
        viewForm = false;
        viewRequests = false;
        viewReports = false;
    }
    
    return (
        <div className='main_div'>
            <Outlet />
        </div>
    )
};