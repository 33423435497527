import Dropzone from 'react-dropzone';
import React, { useState } from 'react';
import removeimg from '../assets/remove.jpg';
import './formComponents.css';








export function FileDropzone(props) {

    function removeFile(targetIndex) {
        props.fileStateSet((prev) => {
            return prev.filter((item, index) => index !== targetIndex);
        });
    };

    function handleFile(e) {
        Object.keys(e).forEach(function (key) {
            let filename = e[key]['name'];
            let file = e[key];
            let fileArray = {
                fileName: filename,
                file: file,
                fileSize: file.size
            };
            console.log(file.size);
            // add item to state using spread operator
            props.fileStateSet(previousState => [...previousState, fileArray]);
        })
    };

    return (
        <div className='formRow'>
            <div className='requestLabelDiv'><label className='requestLabel'>FILE UPLOAD</label></div>
            <div className='requestInputDiv'>
                <Dropzone onDrop={(e) => handleFile(e)}>
                    {({ getRootProps, getInputProps }) => (
                        <section>
                            <div className='outerDropzoneDiv'>
                                <div className='dropzoneStyle' {...getRootProps()}>
                                    <label className='dropzoneTextStyle'>Drag 'n' drop some files here, or click to select files</label>
                                    <input {...getInputProps()} />

                                </div>
                            </div>
                        </section>
                    )}
                </Dropzone>
            </div>

            <div className='filesDisplayDiv'>
                {props.filesState.map((item, i) => {
                    return (
                        <div className='singleFileItem' key={i}>
                            <img className='fileRemoveImg' src={removeimg} alt='Remove' onClick={() => removeFile(i)} />
                            <p className='singleFileText'> {item.fileName}</p>
                        </div>
                    )
                })}
            </div>
        </div>

    );
};








export function SubmitButton(props) {
    return (
        <div className='submitButtonDiv'><input className='formSubmitButton' type="submit" value={props.text} /></div>
    );
};