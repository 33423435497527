import { Auth } from 'aws-amplify';
import { userAppData } from './MainApp.js';
import moment from 'moment';



////// ARRAY SORTING FUNCTIONS

export function sortArrayByNumericalProperty(array, key, order = 'ascending') {
    /*
        This function takes three parameters:

        array: The array of objects you wish to sort.
        key: The property name (as a string) of the objects in the array that you want to sort by.
        order: A string indicating the sort order. It can be either 'ascending' or 'descending'. If not specified, the default order is 'ascending'.
        In the sortArrayByProperty function, the sort method is called on the array. Inside the sort method, the values of the specified property (key) are first converted to integers (using parseInt) because your epoch values are represented as strings in the given array. Then, based on the order specified, the function compares these values to sort the array accordingly.

        In the example usage, the dataCallsState array is sorted by the submissionEpoch property in descending order, so the object with the highest submissionEpoch value will be first, and the one with the lowest value will be last.
        */
    return array.sort((a, b) => {
        let valueA = parseInt(a[key], 10); // assuming the property is a string that should be compared as an integer
        let valueB = parseInt(b[key], 10); // assuming the property is a string that should be compared as an integer

        if (order.toLowerCase() === 'ascending') {
            return valueA - valueB;
        } else { // default to descending if order is not explicitly 'ascending'
            return valueB - valueA;
        }
    });
}

// same as above for for alphabetical
export function sortArrayByProperty(array, key, order = 'ascending') {
    return array.sort((a, b) => {
        let valueA = a[key];
        let valueB = b[key];

        // Check if the values are strings that contain only digits (likely numerical)
        if (!isNaN(Number(valueA)) && !isNaN(Number(valueB))) {
            valueA = parseInt(valueA, 10);
            valueB = parseInt(valueB, 10);
        }

        if (order.toLowerCase() === 'ascending') {
            if (valueA < valueB) {
                return -1;
            }
            if (valueA > valueB) {
                return 1;
            }
            return 0;
        } else { // default to descending if order is not explicitly 'ascending'
            if (valueA > valueB) {
                return -1;
            }
            if (valueA < valueB) {
                return 1;
            }
            return 0;
        }
    });
}

//////  DATE AND TIME FUNCTIONS
export function thisWeekEpochRange() {
    const now = Date.now();
    const { startOfWeek, add, setHours, setMinutes, setSeconds, setMilliseconds, format, parseISO } = require('date-fns');
    // Get the current date and time
    const currentDate = new Date();
    // Calculate the date for the previous Monday at 1 am
    const previousMonday = setMilliseconds(setSeconds(setMinutes(setHours(startOfWeek(currentDate), 1), 0), 0), 0);
    // Format the date to a string in ISO 8601 format and parse it back to get the epoch time (in seconds)
    let lowerThresholdEpoch = Math.floor(parseISO(format(previousMonday, "yyyy-MM-dd'T'HH:mm:ss.SSSX")) / 1000);
    let upperThresholdEpoch = now;

    const epochRanges = {
        'upper': upperThresholdEpoch,
        'lower': lowerThresholdEpoch
    };

    return (epochRanges);

};

export function lastWeekEpochRange() {
    const now = Date.now();
    const { startOfWeek, add, setHours, setMinutes, setSeconds, setMilliseconds, format, parseISO } = require('date-fns');
    // Get the current date and time
    const currentDate = new Date();
    // Calculate the date for the previous Monday at 1 am
    const previousMonday = setMilliseconds(setSeconds(setMinutes(setHours(startOfWeek(currentDate), 1), 0), 0), 0);
    // Format the date to a string in ISO 8601 format and parse it back to get the epoch time (in seconds)
    let upperThresholdEpoch = Math.floor(parseISO(format(previousMonday, "yyyy-MM-dd'T'HH:mm:ss.SSSX")) / 1000);
    let lowerThresholdEpoch = upperThresholdEpoch - 604800;

    const epochRanges = {
        'upper': upperThresholdEpoch,
        'lower': lowerThresholdEpoch
    };

    return (epochRanges);
};


export function epochToReadable(gmtSeconds) {
    let d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(gmtSeconds);
    let formattedTime = d.toLocaleString("en-GB", {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    });
    return (formattedTime);
};

export function epochToReadableTime(gmtSeconds) {
    let d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(gmtSeconds);
    let formattedTime = d.toLocaleString("en-GB", {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false // This ensures you get a 24-hour format
    });
    return (formattedTime);
};







function handleErrors(response, apiErrorStateSet) {
    if (!response.ok) {
        apiErrorStateSet(true);
    }
    return response;
};

export function SEC_ApiCall(primaryUrl, params, apiErrorStateSet) {
    return new Promise((resolve, reject) => {
        Auth.currentAuthenticatedUser({ bypassCache: true }) // get new auth token
            .then(user => {
                let newToken = user["signInUserSession"]["idToken"]["jwtToken"];
                userAppData['idToken'] = newToken;
                // add params to url
                const entries = Object.entries(params);

                let url = 'https://api.schoolengine.co.uk/prod/' + primaryUrl;

                if (entries.length > 0) {
                    let count = 0;
                    for (const [name, value] of entries) {
                        if (count == 0) {
                            url = url + '?' + name + '=' + value;
                            count = count + 1;
                        } else {
                            url = url + '&' + name + '=' + value;
                            count = count + 1;
                        }
                    };
                };
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': newToken
                    },
                }).then(response => {
                    //console.log(response); // Log the full response
                    return handleErrors(response, apiErrorStateSet);
                }).then(response => response.json())
                    .then(data => {
                        //console.log(data); // Log the parsed data
                        resolve(data);
                        if (data.data == 'error') {
                            apiErrorStateSet(true);
                        }
                    }).catch(error => { // catches failed to fetch errors such as no internet when submit button is clicked
                        apiErrorStateSet(true);
                        console.log('api catch error');
                        console.log(error);
                        resolve(error);
                    })
            })
    }).catch(err => console.log("Error Refreshing Token or Error with API Call"));
};

async function generateNewToken() {
    const response = await Auth.currentAuthenticatedUser({ bypassCache: true });
    let newToken = response["signInUserSession"]["idToken"]["jwtToken"];
    userAppData['idToken'] = newToken;
    return newToken;
};

export async function SEC_FileApiPost(primaryUrl, body, apiErrorStateSet) {
    try {
        const submit = await fetch(primaryUrl, { method: 'POST', body: body });
        const response = handleErrors(submit, apiErrorStateSet);
        return (response);
    } catch (e) {
        apiErrorStateSet('error');
        console.log(e);
    }
};

export async function SEC_DataApiPost(primaryUrl, body, params, apiErrorStateSet) {
    try {
        const token = await generateNewToken();
        // add params to url
        const entries = Object.entries(params);
        let url = 'https://api.schoolengine.co.uk/prod/' + primaryUrl;
        if (entries.length > 0) {
            let count = 0;
            for (const [name, value] of entries) {
                if (count == 0) {
                    url = url + '?' + name + '=' + value;
                    count = count + 1;
                } else {
                    url = url + '&' + name + '=' + value;
                    count = count + 1;
                }
            };
        };
        const submit = await fetch(url, { method: 'POST', body: body, headers: { 'Authorization': token } });
        const response = handleErrors(submit, apiErrorStateSet);
        const data = response.json();
        return (data);
    } catch (e) {
        apiErrorStateSet('error');
        console.log(e);
    }
};

export function fileUploadFormDataGenerator(uploadUrl, item) {
    let formData = new FormData();
    formData.append("key", uploadUrl["fields"]["key"]);
    formData.append("policy", uploadUrl["fields"]["policy"]);
    formData.append("x-amz-algorithm", uploadUrl["fields"]["x-amz-algorithm"]);
    formData.append("x-amz-credential", uploadUrl["fields"]["x-amz-credential"]);
    formData.append("x-amz-date", uploadUrl["fields"]["x-amz-date"]);
    formData.append("x-amz-security-token", uploadUrl["fields"]["x-amz-security-token"]);
    formData.append("x-amz-signature", uploadUrl["fields"]["x-amz-signature"]);
    formData.append("file", item.file);
    return (formData);
}

export function fileDownload(newName, oldName) {
    let url = 'general/SignedURLs?type=get&newName=' + newName + '&oldName=' + oldName;
    var params = {};
    SEC_ApiCall(url, params)
        .then(response => {
            let a = document.createElement('a');
            a.href = response;
            a.download = oldName || 'download';
            a.click();
            a.remove();
        })
};



export function fileDownloadAll(filesData) {
    let url = 'general/SignedURLs';
    fetch(url, {
        method: 'POST',
        body: filesData,
        headers: {
            'Authorization': userAppData['idToken'],
        },
    }).then(response => response.json())
        .then(response => {
            Object.values(response).forEach(val => {
                let url = val["url"];
                let oldName = val["oldName"]
                fetch(url, {
                    method: 'GET',
                })
                    .then(response => response.blob())
                    .then(blob => {
                        var url = window.URL.createObjectURL(blob);
                        var a = document.createElement('a');
                        a.href = url;
                        a.download = oldName;
                        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                        a.click();
                        a.remove();  //afterwards we remove the element again         
                    });
            })
        })
};



export function accessBoolean(val) {
    if (val > 0) {
        return true;
    } else {
        return false;
    }
}