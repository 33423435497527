import React, { useState, useEffect } from 'react';
import { userAppData } from '../MainApp.js';
import './OnCallActiveCall.css';
import { epochToReadableTime, SEC_DataApiPost, epochToReadable } from '../func.js';
import { TiTick } from "react-icons/ti";
import { IoPersonAdd } from "react-icons/io5";
import { MdOutlineNoteAdd } from "react-icons/md";
import Select from 'react-select';
import BasicSingleLineTextEntry from '../common/formComponents/BasicSingleLineTextEntry.js';

export function OnCallActiveCall(props) {
    // permissions
    const [allowManageCallsState, setAllowManageCallsState] = useState(false);

    const [callUpdatingState, setCallUpdatingState] = useState(false);
    const [callUpdatingErrorState, setCallUpdatingErrorState] = useState(false);
    const [postApiErrorState, setPostApiErrorState] = useState(false);
    const [assignedUserState, setAssignedUserState] = useState('None');
    const [notesArrayState, setNotesArrayState] = useState(null);
    const [viewAssignSltState, setViewAssignSltState] = useState(false);
    const [viewAddNoteState, setViewAddNoteState] = useState(false);

    const [assignedSltState, setAssignedSltState] = useState('');
    // Add note states
    const [newNoteApiState, setNewNoteApiState] = useState(false);
    const [newNoteTextState, setNewNoteTextState] = useState('');
    const [newNoteTextCharacterCountState, setNewNoteTextCharacterCountState] = useState(0);
    const [newNoteTextHasErrorState, setNewNoteTextHasErrorState] = useState(false);
    const [newNoteTextErrorTextState, setNewNoteTextErrorTextState] = useState(false);

    // State for managing button text and API progress
    const [submitButtonTextState, setSubmitButtonTextState] = useState('Complete Call');
    const [postApiInProgressState, setPostApiInProgressState] = useState(false);

    useEffect(() => {
        setAllowManageCallsState(userAppData['onCall']['allowManageCalls']);
        // This checks if props.data['assignedUser'] is not null and not undefined.
        if (props.data['assignedUser'] != 'null') {
            setAssignedUserState(props.data['assignedUser']);
        } else {
            // If props.data['assignedUser'] is null or undefined, set 'None'
            setAssignedUserState('None');
        }
        setNotesArrayState(props.data['notes']);
    }, [props.data['assignedUser']]);

    const findTypeColour = (typeName) => {
        const type = props.callTypes.find(type => type.typeName === typeName);
        return type ? type.colourCode : '#f2f2f2'; // Replace 'defaultColour' with a default colour of your choice
    };
    // Example of using findTypeColour
    const callTypeColour = findTypeColour(props.data['callType']);

    function postApiError() {
        setPostApiErrorState(true);
        setCallUpdatingState(false);
        setCallUpdatingErrorState(true);
    }

    async function callComplete() {
        setPostApiInProgressState(true);
        setSubmitButtonTextState('Completing...');
        setCallUpdatingState(true);
        var params = {};
        let formData = {
            "id": props.data['id']
        };
        let url = 'oncall/updateCall/complete';
        try {
            const response = await SEC_DataApiPost(url, formData, formData, postApiError);
            // remove call from activeCallsState
            // Filter out the call by ID
            const updatedActiveCalls = props.activeCallsState.filter(call => call.id !== props.data['id']);
            // Update the activeCallsState
            props.setActiveCallsState(updatedActiveCalls);
            //trigger full api call
            var params = {};
            props.apiCall(params);
            setSubmitButtonTextState('Complete Call');
            setPostApiInProgressState(false);
        } catch (e) {
            console.log(e);
            var params = {};
            props.apiCall(params);
            setSubmitButtonTextState('Complete Call');
            setPostApiInProgressState(false);
            setCallUpdatingState(false);
        }
    };

    async function assignSlt(selectInput) {
        let displayName = selectInput['displayName'];
        let cogId = selectInput['cogId'];
        if (assignedUserState != displayName) {
            setCallUpdatingErrorState(false);
            setCallUpdatingState(true);

            setAssignedSltState(cogId)
            var params = {};
            let formData = {
                "id": props.data['id'],
                "slt": cogId
            };
            let url = 'oncall/updateCall/assign';
            try {
                const response = await SEC_DataApiPost(url, formData, formData, postApiError);
                setCallUpdatingState(false);
                var params = {};
                props.apiCall(params);
            } catch (e) {
                console.log(e);
                var params = {};
                props.apiCall(params);
                setCallUpdatingState(false);
            }
        } else {
            setViewAssignSltState(false);
            setCallUpdatingErrorState(true);
        }
    };

    async function saveNote() {
        setCallUpdatingErrorState(false);
        setCallUpdatingState(true);
        var params = {};
        let formData = {
            "id": props.data['id'],
            "note": newNoteTextState
        };
        let url = 'oncall/updateCall/notes';
        try {
            const response = await SEC_DataApiPost(url, formData, formData, postApiError);
            setCallUpdatingState(false);
            var params = {};
            props.apiCall(params);
        } catch (e) {
            console.log(e);
            var params = {};
            props.apiCall(params);
            setCallUpdatingState(false);
            setCallUpdatingErrorState(true);
        }

    };

    function actionButtonClicked(buttonId) {
        switch (buttonId) {
            case 'assign':
                setViewAddNoteState(false);
                if (viewAssignSltState) {
                    setViewAssignSltState(false);
                } else {
                    setViewAssignSltState(true);
                }
                return null;
            case 'note':
                setViewAssignSltState(false);
                if (viewAddNoteState) {
                    setViewAddNoteState(false);
                } else {
                    setViewAddNoteState(true);
                }
                return null;
            default:
                setViewAssignSltState(false);
                setViewAddNoteState(false);
                return null;
        }
    };

    return (
        <div className='oncall_activeCall' >
            <div className='oncall_activeCallFirstRow' style={{ backgroundColor: callTypeColour }} >

                <div className='oncall_activeCallIdRow_Left'>
                    <div className='oncall_activeCallIdRow' >
                        <p className='oncall_activeCall_id'>ID: {props.data['id']}</p>
                    </div>

                    <div className='oncall_activeCallIdRow' >
                        <p className='oncall_activeCall_text'>{props.data['pupilId']}</p>
                        <p className='oncall_activeCall_text'>{props.data['callType']}</p>
                        <p className='oncall_activeCall_text'>{epochToReadableTime(props.data['submissionEpoch'])}</p>
                    </div>
                </div>

                <div className='oncall_activeCallIdRow_Right'><p className='oncall_activeCall_todayCount'>{props.data["todayOncallCount"]}</p><p className='oncall_activeCall_todayCountText'>Today</p></div>

            </div>


            <div className='oncall_activeCallSecondRow' >
                <p className='oncall_activeCall_SecondRowText'>Location: {props.data['location']}</p>
                <p className='oncall_activeCall_SecondRowText'>Staff: {props.data['loggingUser']}</p>
            </div>
            <p className='oncall_activeCall_description'>Assigned: {assignedUserState}</p>
            <p className='oncall_activeCall_description'>Description: {props.data['description']}</p>
            {notesArrayState && notesArrayState.length > 0 ? (
                <div className='oncall_activeCall_notes_div'>
                    <p className='oncall_activeCall_description'>Notes:</p>
                    {notesArrayState.map((item) =>
                        <div key={item['dateTime'] + item['user']} className='oncall_activeCall_notes_inner_div'>
                            <div className='oncall_activeCall_notes_header_div'>
                                <p className='oncall_activeCall_notes_header_text'>{item['user']}</p>
                                <p className='oncall_activeCall_notes_header_text'>{epochToReadable(item['dateTime'])}</p>
                            </div>
                            <div className='oncall_activeCall_notes_text_div'>
                                <p className='oncall_activeCall_notes_text'>{item['note']}</p>
                            </div>
                        </div>
                    )
                    }
                </div>
            ) : null}

            <div className='oncall_activeCallActionsRow'>

                {viewAssignSltState ? (
                    <div className='oncall_activeCall_AssignSltDiv'>
                        <Select
                            className='oncall_Pupil_Selector'
                            maxMenuHeight={'200px'}
                            value={assignedSltState}
                            getOptionLabel={option => option.displayName}
                            getOptionValue={option => option.cogId}
                            options={props.slt}
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    boxShadow: "none",
                                    border: "none",
                                    backgroundColor: "#ffffff",
                                    color: "#000000",
                                    width: "100%"
                                })
                            }}
                            placeholder='Select SLT'
                            noOptionsMessage={() => 'Unable to find available SLT staff'}
                            onChange={assignSlt}
                            isSearchable
                        />
                    </div>
                ) : null}

                {viewAddNoteState ? (
                    <div className='oncall_activeCall_NewNoteDiv'>
                        <BasicSingleLineTextEntry
                            display='col'
                            title='Note'
                            data={newNoteTextState}
                            setData={setNewNoteTextState}
                            nullAllowed={true}
                            displayCounter={true}
                            maxCharacters='100'
                            onError={newNoteTextHasErrorState}
                            onErrorSet={setNewNoteTextHasErrorState}
                            errorText={newNoteTextErrorTextState}
                            errorTextSet={setNewNoteTextErrorTextState}
                            characterCount={newNoteTextCharacterCountState}
                            setCharacterCount={setNewNoteTextCharacterCountState}
                        />
                        <div className='oncall_newNote_submit' onClick={() => saveNote()} style={{ opacity: newNoteApiState ? 0.5 : 1, pointerEvents: newNoteApiState ? 'none' : 'auto' }}>
                            <p className='oncall_newNote_submit_button_text'>Save Note</p>
                        </div>

                    </div>
                ) : null}

                {allowManageCallsState ? <div className='oncall_activeCallAssignButton' onClick={() => actionButtonClicked('assign')} style={{ opacity: callUpdatingState ? 0.3 : 1, pointerEvents: callUpdatingState ? 'none' : 'auto' }}><IoPersonAdd style={{ color: '#ffffff', fontSize: '40px' }} /></div> : null}
                <div className='oncall_activeCallAddNoteButton' onClick={() => actionButtonClicked('note')} style={{ opacity: callUpdatingState ? 0.3 : 1, pointerEvents: callUpdatingState ? 'none' : 'auto' }}><MdOutlineNoteAdd style={{ color: '#ffffff', fontSize: '40px' }} /></div>
                <div className='oncall_activeCallCompleteButton' onClick={() => !postApiInProgressState && callComplete()} style={{ opacity: postApiInProgressState ? 0.5 : 1, pointerEvents: postApiInProgressState ? 'none' : 'auto' }}><TiTick style={{ color: '#ffffff', fontSize: '40px' }} /></div>

            </div>

            {callUpdatingState ? (<div className='oncall_activeCallUpdatingRow' >
                <div>Call Updating...</div>
            </div>) : null}

            {callUpdatingErrorState ? (<div className='oncall_activeCallUpdatingErrorRow' >
                <div>Call Update Error</div>
            </div>) : null}

        </div>
    )
};