import './StandardMultiLineTextEntry.css';
import React, { useState } from 'react';

const errorBorderStyle = {
    'border': '3px solid red',
    'borderRadius': '6px',
    'verticalAlign': 'middle',
    'marginTop': '4px',
    'fontSize': '18px',
    'fontFamily': 'Verdana, Geneva, sans-serif',
    'color': '#666666',
};
const normalBorderStyle = {
    'border': 'none',
    'verticalAlign': 'middle',
    'marginTop': '4px',
    'fontSize': '18px',
    'fontFamily': 'Verdana, Geneva, sans-serif',
    'color': '#666666',
};

export function StandardMultiLineTextEntry(props) {
    function handleValueChange(event) {
        let titleStr = event.target.value;
        props.setCharacterCount(titleStr.length);
        if (props.characterCount >= props.maxCharacters) {
            props.setData(event.target.value);
            props.onErrorSet(true);
            let text = props.title + " must be " + props.maxCharacters + " characters or less";
            props.errorTextSet(text);
        } else if (event.target.value == '' && props.nullAllowed == false) {
            props.onErrorSet(true);
            props.setData('');
            let text = props.title + " cannot be blank";
            props.errorTextSet(text);
        } else {
            props.onErrorSet(false);
            props.errorTextSet('');
            props.setData(event.target.value);
        }
    }

    return (
        <div className='mlteFormRow'>
            <div className='mlteTitleDiv'><label className='mlteTitleLabel'>{props.title}</label></div>
            <div className='mlteInputDiv'>
                <textarea style={props.onError ? errorBorderStyle : normalBorderStyle} className='mlteTextArea' type="text" value={props.data} onChange={handleValueChange} />{props.displayCounter ? <label className='mlteCounterLabel'>{props.characterCount}/{props.maxCharacters}</label> : null}<label className='mlteErrorLabel'>{props.errorText}</label>
            </div>
        </div>
    )
};
