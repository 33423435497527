import React from 'react';
import { Line } from 'react-chartjs-2';
import { calculateWeeklyCalls } from '../OnCallFunc.js';
import Chart from 'chart.js/auto';

export function WeeklyCallTrendsLineChart({ data }) {

    const weeklyCalls = calculateWeeklyCalls(data);
    const dates = Object.keys(weeklyCalls).sort((a, b) => new Date(a.split('/')[2], a.split('/')[1] - 1, a.split('/')[0]) - new Date(b.split('/')[2], b.split('/')[1] - 1, b.split('/')[0]));
    const callCounts = dates.map(date => weeklyCalls[date]);

    let lineChartData = {
        labels: dates,
        datasets: [{
            label: 'Week Call Count',
            data: callCounts,
            fill: false,
            lineTension: 0,
            backgroundColor: "rgb(196,142,56)", // dot colour
            borderColor: "rgb(230, 230, 230)", // line colour
            tension: 0.5
        }]
    };
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                display: false
            },
            tooltip: {
                enabled: true,  // Set this to false if you want to disable tooltips completely

            }
        },
    };

    return (
        <div>
            <Line data={lineChartData} options={options} />
        </div>
    );
};
