import React from 'react';
import { userAppData } from '../MainApp.js';
import MaintenanceSrv from './MaintenanceSingleView.js';
import MaintenanceRequests from './MaintenanceRequests.js';
import { MaintenanceForm } from './MaintenanceForm.js';
import { Outlet, useNavigate } from "react-router-dom";
import './Maintenance.css';



export default function Maintenance() {
    let navigate = useNavigate();
    const MaintenanceUser = userAppData["MaintenanceUser"];

    if (MaintenanceUser == 0) {
        navigate("/app");
    }

    
    return (
        <div className='main_div'>
            <Outlet />
        </div>
    )
};