import React, { useState, useEffect } from 'react';
import './bookingsTables.css';
import FreeRoomButton from './freeRoomButton.js';
import CancelRoomButton from './cancelRoomButton.js';
import LoadingComp from '../common/loading.js';
import sadSE from '../assets/SE_sad.svg';


// props:
// roomName
// lesson
// cancelBookingFunction
// bookingFunction
//
//
export function DailyBookingsTableCell(props) {

    // room is booked (not by user)
    if (props.rooms[props.roomName][props.lesson.name].userId != '' && props.rooms[props.roomName][props.lesson.name].ownBooking == false) {
        return (
            <td className='bookingsTableSingleRoomData' key={props.roomName}>
                <React.Fragment>
                    {props.rooms[props.roomName][props.lesson.name].userId}
                    <br />
                    {props.rooms[props.roomName][props.lesson.name].class}
                </React.Fragment>
            </td>
        )
    }

    if (props.rooms[props.roomName][props.lesson.name].ownBooking == true && props.rooms[props.roomName][props.lesson.name].userId != '') {
        return (
            <td className='bookingsTableSingleRoomData' key={props.roomName}>
                <CancelRoomButton user={props.rooms[props.roomName][props.lesson.name].userId} class={props.rooms[props.roomName][props.lesson.name].class} lesson={props.lesson.name} room={props.roomName} cancelBookingFunction={props.cancelBookingFunction} />
            </td>
        )
    } else {
        return (
            <td className='bookingsTableSingleRoomData' key={props.roomName}>
                <FreeRoomButton lesson={props.lesson.name} room={props.roomName} bookingFunction={props.bookingFunction} />
            </td>
        )
    }




};





// props:
// lessonStructure - used for the row labels
// roomsData - the details of each lesson and the booking for it
// bookingFunction - the fucntion used to send api call to book room.
//
//
//
export function DailyBookingsTable(props) {

    const lessonStructure = props.lessonStructure;
    const rooms = props.roomsData;

    // Get the names of the rooms from the keys in the "rooms" object
    const roomNames = Object.keys(rooms);
    if (!props.dateChangeIsLoadedState) {
        return (
            <LoadingComp />
        )
    } else {
        if (roomNames === undefined || roomNames.length == 0) {
            return (
                <div className="bookingsErrorDiv">
                    <img className='sadSeBookings' src={sadSE} alt='Sad School Engine' />
                    <h1 className='bookingsErrorHeaderText'>No booking information for the selected date</h1>
                    <h3 className='bookingsErrorMessage'>Please choose a different date</h3>
                </div>
            )
        } else if (roomNames != undefined || roomNames.length != 0) {
            return (
                <table className='bookingsTable'>
                    <thead>
                        <tr className='bookingsTableHeader'>
                            <th>Lesson</th>
                            {roomNames.map((roomName) => (
                                <th key={roomName}>
                                    {roomName.replace(/_/g, " ")}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {lessonStructure.map((lesson) =>
                        (
                            <tr className='bookingsTableRow' key={lesson.name}>
                                <td className='bookingsTableData'>{lesson.name}</td>
                                {roomNames.map((roomName) => (
                                        <DailyBookingsTableCell key={roomName} cancelBookingFunction={props.cancelBookingFunction} bookingFunction={props.bookingFunction} rooms={rooms} roomName={roomName} lesson={lesson} />
                                ))}
                            </tr>
                        )
                        )}
                    </tbody>
                </table>

            )
        } else {
            return (
                <LoadingComp />
            )
        }

    }


};
