import React from 'react';
import ReactDOM from "react-dom/client";
import './index.css';
import App from './App';
import { register as registerServiceWorker } from './serviceWorkerRegistration'; // PWA Info
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
Amplify.configure(awsExports);



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

registerServiceWorker();