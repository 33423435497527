import React, { useEffect, useState } from 'react';
import { userAppData } from '../MainApp.js';
import './OnCallPendingCall.css';
import { epochToReadableTime, SEC_DataApiPost } from '../func.js';




export function OnCallPendingCall(props) {
    //permissions states
    const [allowManageCallsState, setAllowManageCallsState] = useState(false);

    const [postApiErrorState, setPostApiErrorState] = useState(false);
    const [callUpdatingState, setCallUpdatiingState] = useState(false);

    useEffect(() => {
        setAllowManageCallsState(userAppData['onCall']['allowManageCalls']);
    }, []);

    async function updateCallApiCall() {
        setCallUpdatiingState(true);
        var params = {};
        let formData = {
            "id": props.data['id']
        };
        let url = 'oncall/updateCall/accept';
        try {
            const response = await SEC_DataApiPost(url, formData, formData, setPostApiErrorState)
            // Find the call in pendingCallsState by its ID
            const callIndex = props.pendingCallsState.findIndex(call => call.id === props.data['id']);
            if (callIndex === -1) return; // Call not found in pending, so exit the function
            // Create a copy of the call and update its isAcknowledged property
            const updatedCall = { ...props.pendingCallsState[callIndex], isAcknowledged: true };
            // Create a new array for pending calls without the acknowledged call
            const newPendingCalls = [...props.pendingCallsState.slice(0, callIndex), ...props.pendingCallsState.slice(callIndex + 1)];
            // Add the updated call to activeCallsState
            const newActiveCalls = [updatedCall, ...props.activeCallsState];
            // Update state
            props.setPendingCallsState(newPendingCalls);
            props.setActiveCallsState(newActiveCalls);
            //trigger full api call
            var params = {};
            props.apiCall(params);
            setCallUpdatiingState(false);
        } catch (e) {
            console.log(e);
            setCallUpdatiingState(false);
        }

    };


    // change div to UPDATING.. as soon as clicked to signal its working and prevent multiple api calls from over clicking.
    if (allowManageCallsState) {
        return (
        <div className='oncall_pendingCall' onClick={() => !callUpdatingState && updateCallApiCall()} style={{ animation: callUpdatingState ? 'null' : 'pulse 4s infinite', opacity: callUpdatingState ? 0.5 : 1, pointerEvents: callUpdatingState ? 'none' : 'auto' }}>
            <p className='oncall_pendingCall_text'>ID: {props.data['id']}</p>
            <p className='oncall_pendingCall_text'>{props.data['pupilId']}</p>
            <p className='oncall_pendingCall_text'>{props.data['callType']}</p>
            <p className='oncall_pendingCall_text'>{epochToReadableTime(props.data['submissionEpoch'])}</p>
        </div>
    )
        } else {
            return (
                <div className='oncall_pendingCall_basic'>
                    <p className='oncall_pendingCall_text'>ID: {props.data['id']}</p>
                    <p className='oncall_pendingCall_text_basic'>{props.data['pupilId']}</p>
                    <p className='oncall_pendingCall_text_basic'>{props.data['callType']}</p>
                    <p className='oncall_pendingCall_text_basic'>{epochToReadableTime(props.data['submissionEpoch'])}</p>
                </div>
            )
        }
};