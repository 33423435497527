import React from 'react';
import './modal.css';
import loadinggif from '../assets/Loading.gif';
import sadSE from '../assets/SE_sad.svg';
import { OncallAddNewCallForm } from '../oncall/OnCallAddNewCallForm';
import { IoClose } from "react-icons/io5";

export function RequestSubmittedModalContent(props) {
  if (props.apiState === 'loading') {
    return (
      <div className="loadingGifDiv">
        <img className='loadingGif' src={loadinggif} alt='Loading' />
      </div>
    )
  } else if (props.apiState === 'success') {
    return (
      <div className="modal-body">
        <h1 className='modalHeaderText'>Request Submitted</h1>
        <h3 className='modalMessage'>Your {props.requestType} request has been submitted with the ID number of</h3>
        <h3 className='modalMessageId'>{props.rid}</h3>
      </div>
    )
  } else {
    return (
      <div className="modal-body">
        <img className='sadSeModal' src={sadSE} alt='Sad School Engine' />
        <h1 className='modalHeaderText'>Error During Submission</h1>
        <h3 className='modalMessage'>Your request has not been submitted</h3>
        <h3 className='modalMessage'>Do not refresh this page, close this window and click submit again</h3>
      </div>
    )
  }
}
// modalActiveState setStateFunction requestType rid apiState onModalClose
export function RequestSubmittedModal(props) {

  const modalHideCss = {
    display: "none",
    /* Hidden by default */
    position: "fixed",
    /* Stay in place */
    zIndex: "1",
    /* Sit on top */
    paddingTop: "150px",
    /* Location of the box */
    left: "0",
    top: "0",
    width: "100%",
    /* Full width */
    height: "100%",
    /* Full height */
    overflow: "auto",
    /* Enable scroll if needed */
    backgroundColor: "rgb(0, 0, 0)",
    /* Fallback color */
    backgroundColor: "rgba(0, 0, 0, 0.4)"
    /* Black w/ opacity */
  }

  const modalShowCss = {
    display: "block",
    /* Hidden by default */
    position: "fixed",
    /* Stay in place */
    zIndex: "1",
    /* Sit on top */
    paddingTop: "150px",
    /* Location of the box */
    left: "0",
    top: "0",
    width: "100%",
    /* Full width */
    height: "100%",
    /* Full height */
    overflow: "auto",
    /* Enable scroll if needed */
    backgroundColor: "rgb(0, 0, 0)",
    /* Fallback color */
    backgroundColor: "rgba(0, 0, 0, 0.4)"
    /* Black w/ opacity */
  }

  let modalCss = modalHideCss;

  if (props.modalActiveState == true) {
    modalCss = modalShowCss;
  } else {
    modalCss = modalHideCss;
  }

  function hideModal() {
    props.setStateFunction(false);
    props.onModalClose();
  }

  return (
    <div style={modalCss} className="modal" onClick={hideModal}>
      <div className="modal-content">
        <div className="modal-header">
          <span className="close" onClick={hideModal}>&times;</span>
        </div>

        <RequestSubmittedModalContent requestType={props.requestType} rid={props.rid} apiState={props.apiState} />

        <div className="modal-footer">

        </div>
      </div>
    </div>
  )
}









export function DataUpdateModalContent(props) {
  if (props.apiState === 'loading') {
    return (
      <div className='loadingGifDiv'>
        <img className='loadingGif' src={loadinggif} alt='Loading' />
      </div>
    )
  } else if (props.apiState === 'success') {
    return (
      <div className="modal-body">
        <h1 className='modalHeaderText'>Request Updated</h1>
        <h3 className='modalMessage'>The request has been sucessfully updated</h3>
      </div>
    )
  } else {
    return (
      <div className="modal-body">
        <img className='sadSeModal' src={sadSE} alt='Sad School Engine' />
        <h1 className='modalHeaderText'>Error Updating Data</h1>
        <h3 className='modalMessage'>The request has not been updated. Do not refresh this page, close this window and click save again.</h3>
      </div>
    )
  }
}
// modalActiveState setStateFunction requestType rid apiState onModalClose
export function DataUpdateModal(props) {

  const modalHideCss = {
    display: "none",
    /* Hidden by default */
    position: "fixed",
    /* Stay in place */
    zIndex: "1",
    /* Sit on top */
    paddingTop: "150px",
    /* Location of the box */
    left: "0",
    top: "0",
    width: "100%",
    /* Full width */
    height: "100%",
    /* Full height */
    overflow: "auto",
    /* Enable scroll if needed */
    backgroundColor: "rgb(0, 0, 0)",
    /* Fallback color */
    backgroundColor: "rgba(0, 0, 0, 0.5)"
    /* Black w/ opacity */
  }

  const modalShowCss = {
    display: "block",
    /* Hidden by default */
    position: "fixed",
    /* Stay in place */
    zIndex: "1",
    /* Sit on top */
    paddingTop: "150px",
    /* Location of the box */
    left: "0",
    top: "0",
    width: "100%",
    /* Full width */
    height: "100%",
    /* Full height */
    overflow: "auto",
    /* Enable scroll if needed */
    backgroundColor: "rgb(0, 0, 0)",
    /* Fallback color */
    backgroundColor: "rgba(0, 0, 0, 0.5)"
    /* Black w/ opacity */
  }

  let modalCss = modalHideCss;

  if (props.modalActiveState == true) {
    modalCss = modalShowCss;
  } else {
    modalCss = modalHideCss;
  }

  function hideModal() {
    props.setStateFunction(false);
    props.onModalClose();
  }

  return (
    <div style={modalCss} className="modal" onClick={hideModal}>
      <div className="modal-content">
        <div className="modal-header">
          <span className="close" onClick={hideModal}>&times;</span>
        </div>

        <DataUpdateModalContent requestType={props.requestType} apiState={props.apiState} />

        <div className="modal-footer">

        </div>
      </div>
    </div>
  )
}






// ROOM BOOKING MODALS
export function BookingModalContent(props) {
  if (props.type == 'booking') {
    if (props.apiState === 'loading') {
      return (
        <div className='loadingGifDiv'>
          <img className='loadingGif' src={loadinggif} alt='Loading' />
        </div>
      )
    } else if (props.apiState === 'success') {
      return (
        <div className="modal-body">
          <h1 className='modalHeaderText'>Booking Confirmed</h1>
          <h3 className='modalMessage'>Your booking for room {props.room} has been made for {props.lesson} on {props.day} {props.date}</h3>
        </div>
      )
    } else {
      return (
        <div className="modal-body">
          <img className='sadSeModal' src={sadSE} alt='Sad School Engine' />
          <h1 className='modalHeaderText'>Error Booking Room</h1>
          <h3 className='modalMessage'>The booking has not been made. Do not refresh this page, close this window and try again.</h3>
        </div>
      )
    }
  } else if (props.type == 'cancel') {
    if (props.apiState === 'loading') {
      return (
        <div className='loadingGifDiv'>
          <img className='loadingGif' src={loadinggif} alt='Loading' />
        </div>
      )
    } else if (props.apiState === 'success') {
      return (
        <div className="modal-body">
          <h1 className='modalHeaderText'>Booking Cancelled</h1>
          <h3 className='modalMessage'>Your booking for room {props.room} has been cancelled for {props.lesson} on {props.day} {props.date}</h3>
        </div>
      )
    } else if (props.type == 'booking') {
      return (
        <div className="modal-body">
          <img className='sadSeModal' src={sadSE} alt='Sad School Engine' />
          <h1 className='modalHeaderText'>Error Cancelling Booking</h1>
          <h3 className='modalMessage'>The booking has not been cancelled. Do not refresh this page, close this window and try again.</h3>
        </div>
      )
    }
  }

}


export function BookingModal(props) {

  const modalHideCss = {
    display: "none",
    /* Hidden by default */
    position: "fixed",
    /* Stay in place */
    zIndex: "1",
    /* Sit on top */
    paddingTop: "150px",
    /* Location of the box */
    left: "0",
    top: "0",
    width: "100%",
    /* Full width */
    height: "100%",
    /* Full height */
    overflow: "auto",
    /* Enable scroll if needed */
    backgroundColor: "rgb(0, 0, 0)",
    /* Fallback color */
    backgroundColor: "rgba(0, 0, 0, 0.6)"
    /* Black w/ opacity */
  }

  const modalShowCss = {
    display: "block",
    /* Hidden by default */
    position: "fixed",
    /* Stay in place */
    zIndex: "1",
    /* Sit on top */
    paddingTop: "150px",
    /* Location of the box */
    left: "0",
    top: "0",
    width: "100%",
    /* Full width */
    height: "100%",
    /* Full height */
    overflow: "auto",
    /* Enable scroll if needed */
    backgroundColor: "rgb(0, 0, 0)",
    /* Fallback color */
    backgroundColor: "rgba(0, 0, 0, 0.6)"
    /* Black w/ opacity */
  }

  let modalCss = modalHideCss;

  if (props.modalActiveState == true) {
    modalCss = modalShowCss;
  } else {
    modalCss = modalHideCss;
  }

  function hideModal() {
    props.setStateFunction(false);
  }

  return (
    <div style={modalCss} className="modal" onClick={hideModal}>
      <div className="modal-content">
        <div className="modal-header">
          <span className="close" onClick={hideModal}>&times;</span>
        </div>

        <BookingModalContent room={props.room} lesson={props.lesson} day={props.day} date={props.date} type={props.type} apiState={props.apiState} />

        <div className="modal-footer">

        </div>
      </div>
    </div>
  )
}




export function OnCallAddNewCallFormModal(props) {

  const modalHideCss = {
    display: "none",
    /* Hidden by default */
    position: "fixed",
    /* Stay in place */
    zIndex: "1",
    /* Sit on top */
    paddingTop: "100px",
    /* Location of the box */
    left: "0",
    top: "0",
    width: "100%",
    /* Full width */
    height: "100%",
    /* Full height */
    overflow: "auto",
    /* Enable scroll if needed */
    backgroundColor: "rgb(0, 0, 0)",
    /* Fallback color */
    backgroundColor: "rgba(0, 0, 0, 0.4)"
    /* Black w/ opacity */
  }

  const modalShowCss = {
    display: "block",
    /* Hidden by default */
    position: "fixed",
    /* Stay in place */
    zIndex: "1",
    /* Sit on top */
    paddingTop: "100px",
    /* Location of the box */
    left: "0",
    top: "0",
    width: "100%",
    /* Full width */
    height: "100%",
    /* Full height */
    overflow: "auto",
    /* Enable scroll if needed */
    backgroundColor: "rgb(0, 0, 0)",
    /* Fallback color */
    backgroundColor: "rgba(0, 0, 0, 0.4)"
    /* Black w/ opacity */
  }

  let modalCss = modalHideCss;

  if (props.modalActiveState == true) {
    modalCss = modalShowCss;
  } else {
    modalCss = modalHideCss;
  }

  function hideModal() {
    props.setStateFunction(false);
    props.onModalClose();
  }

  return (
    <div style={modalCss} className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <IoClose className="closeIcon" size={'50'} onClick={hideModal} />
        </div>

        <OncallAddNewCallForm modalActiveState={props.modalActiveState}/>

        <div className="modal-footer">

        </div>
      </div>
    </div>
  )
}