import React, { useState, useEffect } from 'react';
import './OnCallAddNewCallForm.css';
import { userAppData } from '../MainApp.js';
import Select from 'react-select';
import BasicSingleLineTextEntry from '../common/formComponents/BasicSingleLineTextEntry.js';
import BasicMultiLineTextEntry from '../common/formComponents/BasicMultiLineTextEntry.js';
import { SEC_ApiCall, SEC_DataApiPost } from '../func.js';
import LoadingComp from '../common/loading.js';
import { ApiError } from '../common/apiError.js';



export function OncallAddNewCallForm(props) {
    const [submitButtonTextState, setSubmitButtonTextState] = useState('SUBMIT');
    const [canProxySubmitState, setCanProxySubmitState] = useState(false);

    // API CALL STATES
    const [isLoadedState, setIsLoadedState] = useState(false);
    const [apiErrorState, setApiErrorState] = useState(false);
    const [apiErrorTextState, setApiErrorTextState] = useState('');
    const [dataState, setDataState] = useState([]);
    // POST
    const [postApiInProgressState, setPostApiInProgressState] = useState(false);
    const [postApiErrorState, setPostApiErrorState] = useState(false);
    const [postIsLoadedState, setPostIsLoadedState] = useState(false);

    // pupil name
    const [pupilNameState, setPupilNameState] = useState(null);
    const [requestPupilNameStateHasErrorState, setRequestPupilNameStateHasErrorState] = useState(false);
    const [requestPupilNameStateErrorTextState, setRequestPupilNameStateErrorTextState] = useState(false);
    // call type states
    const [callTypeState, setCallTypeState] = useState(null);
    const [callTypeStateHasErrorState, setCallTypeStateHasErrorState] = useState(false);
    const [callTypeStateErrorTextState, setCallTypeStateErrorTextState] = useState(false);
    // logging user states
    const [loggingUserState, setLoggingUserState] = useState({ 'id': userAppData['cogId']});

    // request location
    const [requestLocationState, setRequestLocationState] = useState('');
    const [requestLocationCharacterCountState, setRequestLocationCharacterCountState] = useState(0);
    const [requestLocationHasErrorState, setRequestLocationHasErrorState] = useState(false);
    const [requestLocationErrorTextState, setRequestLocationErrorTextState] = useState(false);
    // request description
    const [requestDescriptionState, setRequestDescriptionState] = useState('');
    const [requestDescriptionCharacterCountState, setRequestDescriptionCharacterCountState] = useState(0);
    const [requestDescriptionHasErrorState, setRequestDescriptionHasErrorState] = useState(false);
    const [requestDescriptionErrorTextState, setRequestDescriptionErrorTextState] = useState(false);

    // variables for banner
    const [bannerVisible, setBannerVisible] = useState(false);
    const [bannerMessage, setBannerMessage] = useState('');
    const [bannerColor, setBannerColor] = useState('green'); // or 'red'


    async function apiCall() {
        var url = 'oncall/newCall';
        var params = {};
        try {
            const response = await SEC_ApiCall(url, params, setApiErrorState);
            setDataState(response);

            if (userAppData["onCall"]['canProxySubmit']) {
                setCanProxySubmitState(true);
            }
            setIsLoadedState(true);
        } catch (e) {
            setApiErrorState(true);
            setIsLoadedState(true);
            setApiErrorTextState(e);
            console.log(e);
        }
    }

    useEffect(() => {
        if (props.modalActiveState) {
            var params = {};
            apiCall(params);
        }
    }, [props.modalActiveState]);

    const hideBannerAfterDelay = () => {
        setTimeout(() => {
            setBannerVisible(false);
        }, 10000); // Hide after 10 seconds
    };


    async function submitCallApiFunction(params, formData) {
        setPostApiInProgressState(true);
        const url = 'oncall';
        try {
            const response = await SEC_DataApiPost(url, formData, params, setPostApiErrorState);
            if (postApiErrorState) { // This assumes postApiErrorState is accessible; you might need a different check
                throw new Error('Failed to submit the call due to server error.');
            }
            // On success, show success banner and clear form (if needed)
            setBannerMessage('Call submitted successfully.');
            setBannerColor('green');
            setBannerVisible(true);
            hideBannerAfterDelay();
            // Reset form fields
            setCallTypeState(null);
            setPupilNameState(null);
            if (canProxySubmitState) {
                setLoggingUserState(null);
            };
            setRequestLocationState('');
            setRequestDescriptionState('');
            setRequestLocationCharacterCountState(0);
            setRequestDescriptionCharacterCountState(0);
            setIsLoadedState(true);
            setSubmitButtonTextState('SUBMIT')
            setPostApiInProgressState(false);
        } catch (e) {
            setSubmitButtonTextState('SUBMIT')
            setPostApiInProgressState(false);
            setPostIsLoadedState(true);
            setPostApiErrorState(true);
            // On failure, show error banner
            setBannerMessage('Failed to submit the call. Please try again.');
            setBannerColor('red');
            setBannerVisible(true);
            hideBannerAfterDelay();
            console.log(e);
        }
    };

    // Function to manually close the banner
    const closeBanner = () => setBannerVisible(false);

    function submitNewOncallRequest() {
        let pupilNameIsBlank = false;
        let callTypeIsBlank = false;
        let requestLocationIsBlank = false;
        let requestDescriptionIsBlank = false;

        if (pupilNameState === null) {
            pupilNameIsBlank = true;
            setRequestPupilNameStateHasErrorState(true);
            setRequestPupilNameStateErrorTextState('Please select a pupil')
        }
        if (callTypeState === null) {
            callTypeIsBlank = true;
            setCallTypeStateHasErrorState(true);
            setCallTypeStateErrorTextState('Please select a type')
        }
        if (requestLocationState === '') {
            requestLocationIsBlank = true;
            setRequestLocationHasErrorState(true);
            setRequestLocationErrorTextState('Request location cannot be blank')
        }
        if (requestDescriptionState === '') {
            requestDescriptionIsBlank = true;
            setRequestDescriptionHasErrorState(true);
            setRequestDescriptionErrorTextState('Request description cannot be blank')
        }
        if (requestLocationIsBlank || requestDescriptionIsBlank || pupilNameIsBlank || callTypeIsBlank) {
            //error in a field, dont forget to check that a pupil is selected
            console.log('error in field')
        } else {
            function isObjectValid(obj) {
                for (const key in obj) {
                    if (obj.hasOwnProperty(key) && obj[key] === undefined) {
                        // Handle the presence of an undefined value
                        // For example, alert or log
                        console.log(`The property ${key} is undefined.`);
                        return false; // Indicates the object is not valid for submission
                    }
                }
                return true; // All properties are defined
            }
            
            let formObj = {
                "callType": callTypeState["typeName"],
                'pupil': pupilNameState['id'],
                'location': requestLocationState,
                'description': requestDescriptionState,
                'loggingUser': loggingUserState['id']
            }


            if (isObjectValid(formObj)) {
                // Proceed with submitting the object to the API
                setSubmitButtonTextState('SUBMITTING...')
                submitCallApiFunction(formObj, formObj);
            } else {
                // Handle the invalid object, e.g., prompt the user to fill all fields
                setBannerMessage('Failed to submit the call. Please try again.');
                setBannerColor('red');
                setBannerVisible(true);
                hideBannerAfterDelay();
            }


        }
    }




    if (!isLoadedState) {
        return (
            <LoadingComp />
        )
    } else if (apiErrorState) {
        return (
            <ApiError />
        )
    } else {
        return (
            <div className='oncall_new_form'>

                {bannerVisible && (
                    <div className={`oncallPostBanner_${bannerColor}`} onClick={closeBanner}>
                        {bannerMessage}
                    </div>
                )}

                <div>
                    <h1 className='oncall_new_header' >NEW ONCALL REQUEST</h1>
                </div>

                <div className='oncall_new_form_row'>
                    <div className='oncall_new_form_row_col1'>
                        <p className='oncall_new_form_row_text'>Pupil:</p>
                        <Select
                            className='oncall_Pupil_Selector'
                            maxMenuHeight={'200px'}
                            value={pupilNameState}
                            getOptionLabel={option => option.pupilName}
                            getOptionValue={option => option.id}
                            options={dataState['pupils']}
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    boxShadow: "none",
                                    border: "none",
                                    backgroundColor: "#f2f2f2",
                                    color: "#000000",
                                    width: "100%"
                                })
                            }}
                            placeholder='Select or start typing a pupil'
                            noOptionsMessage={() => 'Unable to find pupils'}
                            onChange={setPupilNameState}
                            isSearchable
                        />
                    </div>
                    <div className='oncall_new_form_row_col1'>
                        <p className='oncall_new_form_row_text'>Call Type:</p>
                        <Select
                            className='oncall_Pupil_Selector'
                            maxMenuHeight={'200px'}
                            value={callTypeState}
                            getOptionLabel={option => option.typeName}
                            getOptionValue={option => option.typeName}
                            options={dataState['callTypes']}
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    boxShadow: "none",
                                    border: "none",
                                    backgroundColor: "#f2f2f2",
                                    color: "#000000",
                                    width: "100%"
                                })
                            }}
                            placeholder='Select a call type'
                            noOptionsMessage={() => 'Sorry something went wrong finding call types'}
                            onChange={setCallTypeState}
                            isSearchable
                        />
                    </div>
                </div>
                <div className='oncall_new_form_row'>
                    <div className='oncall_new_form_row_col2'>
                        <p className='oncall_new_form_row_text'>Location:</p>
                        <BasicSingleLineTextEntry
                            data={requestLocationState}
                            setData={setRequestLocationState}
                            nullAllowed={false}
                            displayCounter={true}
                            maxCharacters='40'
                            onError={requestLocationHasErrorState}
                            onErrorSet={setRequestLocationHasErrorState}
                            errorText={requestLocationErrorTextState}
                            errorTextSet={setRequestLocationErrorTextState}
                            characterCount={requestLocationCharacterCountState}
                            setCharacterCount={setRequestLocationCharacterCountState}
                        />
                    </div>
                    <div className='oncall_new_form_row_col2'>

                        <p className='oncall_new_form_row_text'>Reason:</p>
                        <BasicSingleLineTextEntry
                            data={requestDescriptionState}
                            setData={setRequestDescriptionState}
                            nullAllowed={false}
                            displayCounter={true}
                            maxCharacters='80'
                            onError={requestDescriptionHasErrorState}
                            onErrorSet={setRequestDescriptionHasErrorState}
                            errorText={requestDescriptionErrorTextState}
                            errorTextSet={setRequestDescriptionErrorTextState}
                            characterCount={requestDescriptionCharacterCountState}
                            setCharacterCount={setRequestDescriptionCharacterCountState}
                        />
                    </div>
                </div>

                {
                    !canProxySubmitState ? null : (
                        <div className='oncall_new_form_row'>
                            <div className='oncall_new_form_row_col1'>
                                <p className='oncall_new_form_row_text'>Logging Person:</p>
                                <Select
                                    className='oncall_Pupil_Selector'
                                    maxMenuHeight={'200px'}
                                    value={loggingUserState}
                                    getOptionLabel={option => option.displayName}
                                    getOptionValue={option => option.id}
                                    options={dataState['loggableUsers']}
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            boxShadow: "none",
                                            border: "none",
                                            backgroundColor: "#f2f2f2",
                                            color: "#000000",
                                            width: "100%"
                                        })
                                    }}
                                    placeholder='Select the staff member logging the call'
                                    noOptionsMessage={() => 'Sorry something went wrong finding staff data'}
                                    onChange={setLoggingUserState}
                                    isSearchable
                                />
                            </div>
                        </div>



                    )
                }

                <div className='oncall_new_form_row_submit'>
                    <div className='oncall_new_submit' onClick={() => !postApiInProgressState && submitNewOncallRequest(props.updateCallApiCall)} style={{ opacity: postApiInProgressState ? 0.5 : 1, pointerEvents: postApiInProgressState ? 'none' : 'auto' }}>
                        <p className='oncall_call_right_div_action_button_text'>{submitButtonTextState}</p>
                    </div>
                </div>
            </div >
        )
    }
};


