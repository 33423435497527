import React from 'react';
import { Pie } from 'react-chartjs-2';

export function PieChartComponent({ callsArray, callTypeColours, onCallTypeSelect }) {

    let counts = {};

    callsArray.forEach(call => {
        counts[call.callType] = (counts[call.callType] || 0) + 1;
    });

    // Create a mapping of call types to colors
    const colorMap = {};
    callTypeColours.forEach(type => {
        colorMap[type.typeName] = type.colourCode;
    });

    const pieChartData = {
        labels: Object.keys(counts),
        datasets: [{
            data: Object.values(counts),
            backgroundColor: Object.keys(counts).map(type => colorMap[type]),
            hoverOffset: 4
        }]
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'right',
                labels: {
                    boxWidth: 30,
                    padding: 5,
                    font: {
                        size: 16
                    }
                }
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            },
        },
        onClick: (event, elements, chart) => {
            if (elements.length > 0) {
                const chartElement = elements[0];
                const index = chartElement.index;
                const label = chart.data.labels[index];  // Get the label of the clicked segment
                onCallTypeSelect(label);  // Trigger your filter function
            }
        }
    };


    return <Pie data={pieChartData} options={options} />;
}
