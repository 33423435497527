import React from 'react';
import returnIcon from '../assets/1314462.png';
import { useNavigate } from "react-router-dom";




export default function ReturnButton() {
    let navigate = useNavigate();
    return (
        <div className='main_div'>
            <button className='srvToolbarButton' type='button' onClick={() => { navigate(-1) }}><img className='srvToolbarIcon' src={returnIcon} alt='Return' /></button>
        </div>
    );
};