import React from 'react';
import { findSchoolPeriod } from '../OnCallFunc.js';


export function LessonTimesCountTable({ data, timetableTimings, onLessonSelect, selectedLesson }) {
    let timings = {};

    timetableTimings.forEach(record => {
        timings[record.period] = 0;
    });

    data.forEach(call => {
        const period = findSchoolPeriod(call.submissionEpoch, timetableTimings);
        if (timings[period] != null) {
            timings[period]++;
        }
    });

    return (
        <table className="timetable-table">
            <thead>
                <tr className="timetable-header">
                    <th>Period</th>
                    <th>Count</th>
                </tr>
            </thead>
            <tbody>
                {Object.entries(timings).map(([period, count], index) => (
                    <tr key={period}
                        className={`timetable-row ${index % 2 === 0 ? 'row-even' : 'row-odd'} 
                                   ${period === selectedLesson ? 'timetable-row_selected-lesson' : ''}`}  // Apply 'selected-lesson' class if the period is the selected lesson
                        onClick={() => onLessonSelect(period)}>
                        <td>{period}</td>
                        <td>{count}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

