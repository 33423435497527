import './StandardDateSelector.css';
import React, { useState } from 'react';

const errorBorderStyle = {
    'border': '3px solid red',
    'borderRadius': '6px',
    'verticalAlign': 'middle',
    'marginTop': '4px',
    'fontSize': '18px',
    'fontFamily': 'Verdana, Geneva, sans-serif',
    'color': '#666666',
};
const normalBorderStyle = {
    'border': 'none',
    'verticalAlign': 'middle',
    'marginTop': '4px',
    'fontSize': '18px',
    'fontFamily': 'Verdana, Geneva, sans-serif',
    'color': '#666666',
};

export function StandardDateSelector(props) {
    var d = new Date();
    d.setUTCHours(0, 0, 0, 0);
    var currentDate = +d;

    function handleValueChange(event) {
        event.persist();
        if (!props.allowPast) {
            if (Date.parse(event.target.value) < currentDate) {
                props.onErrorSet(true);
                props.errorTextSet('Required date cannot be in the past');
            } else {
                props.onErrorSet(false);
                props.errorTextSet('');
                props.setData(event.target.value);
            }
        } else {
            props.onErrorSet(false);
            props.errorTextSet('');
            props.setData(event.target.value);
        }
        if (props.onChangeFunction) {
            props.onChangeFunction(event.target.value);
        }
    }

    return (
        <div className='sdsFormRow'>
            <div className='sdsTitleDiv'><label className='sdsTitleLabel'>{props.title}</label></div>
            <div className='sdsInputDiv'>
                <input style={props.onError ? errorBorderStyle : normalBorderStyle} className='dateSelector' id='datePicker' type="date" defaultValue={currentDate} onChange={handleValueChange} /><label className='sdsErrorLabel'>{props.errorText}</label>
            </div>
        </div>
    )
};