import React, { useState } from 'react';
import printIcon from '../assets/35495.png';
import saveIcon from '../assets/1103555.png';
import ReturnButton from './returnButton.js';
import {
    fileDownload,
    fileDownloadAll
} from '../func.js';

function printIconClicked() {
    window.print();
}

const errorBorderStyle = {
    'border': '3px solid red',
    'borderRadius': '6px',
    'verticalAlign': 'middle',
    'marginTop': '4px',
    'fontSize': '18px',
    'fontFamily': 'Verdana, Geneva, sans-serif',
    'color': '#666666',
};
const normalBorderStyle = {
    'border': 'none',
    'verticalAlign': 'middle',
    'marginTop': '4px',
    'fontSize': '18px',
    'fontFamily': 'Verdana, Geneva, sans-serif',
    'color': '#666666',
};

export function Srv_Toolbar(props) {
    return (
        <div className='srvToolBar'>
            <div className='srvToolbarLeftDiv'>
                <ReturnButton />
            </div>
            <div className='srvToolbarCentreDiv'>
                {props.saveMessageState ? <div className={props.saveDivState}><p className='saveErrorDisplayMessage'>Request Updated</p></div> : null}
                {props.errorMessageState ? <div className={props.errorDivState}><p className='saveErrorDisplayMessage'>Error - request not updated</p></div> : null}
            </div>
            <div className={props.rightDivState}>
                <button className='srvToolbarButton' type='button' onClick={printIconClicked}><img className='srvToolbarIcon' src={printIcon} alt='Print' /></button>
                <button disabled={props.saveMessageState} className={props.saveMessageState ? 'srvToolbarButtonDisabled' : 'srvToolbarButton'} type='button' onClick={props.saveFunc} value="Submit"><img className='srvToolbarIcon' src={saveIcon} alt='Save' /></button>
            </div>
        </div>
    )
}

export function Srv_Header(props) {
    return (
        <div className='srvHeaderDiv'>
            <h1 className='srvRequestHeader' >{props.text} - {props.value} </h1>
        </div>
    )
}

export function Srv_BasicItem(props) {
    return (
        <div className='srvFormRow'>
            <div className='srvLabelDiv'><label className='srvTitleLabel'>{props.title}:</label></div>
            <div className='srvDataDiv'><label className='srvDataLabel'>{props.data}</label></div>
        </div>
    )
}

export function Srv_SelectorItem(props) {
    return (
        <div className='srvFormRow'>
            <div className='srvLabelDiv'>
                <label className='srvTitleLabel'>{props.title}: </label></div>

            <div className='srvSelectorDiv'>
                <select className='srvRequestSelector' defaultValue={props.defValue} onChange={props.onChangeFunction}>
                    {
                        Object.keys(props.data).map((item, i) => (
                            <option key={i} value={props.data[item].value}>{props.data[item].displayText}</option>
                        ))
                    }
                </select>
            </div>
        </div>
    )
}

export function Srv_Files(props) {
    return (
        <div className='srvFormRow'>
            <div className='srvFilesLabelDiv'>
                <label className='srvTitleLabel'>Files:</label>
            </div>
            <div className='srvFilesDataDiv'>
                <ul className="filesUl">
                    {props.data.map((item, i) => (
                        <div className='srvFileNameDiv' key={"file" + i}>
                            <li className='srvFileName' key={"fname" + i} onClick={() => { fileDownload(item["newName"], item["oldName"]) }}>{item["oldName"]} </li>
                        </div>
                    ))}
                </ul>
            </div>
            <div className='srvFilesDownloadLabelDiv'>
                <button className="srvDownloadAllButton" type="button" onClick={() => { fileDownloadAll(props.filesData) }}>Download All</button>
            </div>
        </div>
    )
}

export function Srv_EventsComp(props) {
    return (
        <div className="srv_rightDiv">
            <div className='srvDescriptionFormRow'>
                <div className='srvDescriptionLabelDiv'><label className='srvTitleLabel'>New Event:</label></div>
                <div className='newEventTextAreaDiv'>
                    <textarea style={props.newEventHasErrorState ? errorBorderStyle : normalBorderStyle} id='newEventTextArea' className='newEventTextArea' onChange={props.handleChangeNewEvent} /><label className='requestTitleErrorLabel'>{props.newEventErrorTextState}</label>
                </div>
            </div>

            <div className='srvDescriptionFormRow'>
                <div className='srvDescriptionLabelDiv'><label className='srvTitleLabel'>Primary Event:</label></div>
                <div className='requestSingleDescription'>
                    <p className='singleDescriptionName'>{props.primaryEvent['user']} {props.primaryEvent['timestamp']}</p>
                    <p className='singleDescriptionDescription'>{props.primaryEvent['eventData']}</p>
                </div>
                <div className='srvDescriptionLabelDiv'><label className='srvTitleLabel'>Additional Events:</label></div>
                {props.additionalEvents.map((item, i) => ( // i is the index of the file in the state e.g. 0, 1 etc and item is the array of the files details
                    <div className='requestSingleDescription' key={"d" + i}>
                        <p className='singleDescriptionName' key={"u" + i}>{item['user']} {item['timestamp']}</p>
                        <p className='singleDescriptionDescription' key={"desc" + i}>{item['eventData']}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export function Srv_CompNotes(props) {
    return (
        <div className='srvFormRow'>
            <div className='srvLabelDiv'><label className='srvTitleLabel'>Completion Notes (not seen by users):</label></div>
            <div className='requestInputDiv'>
                <textarea style={props.errorState ? errorBorderStyle : normalBorderStyle} className='singleTextArea' defaultValue={props.compNotesState} onChange={props.changeNotesFunc} /><label className='requestTitleErrorLabel'>{props.errorState}</label>
            </div>
        </div>
    )
}