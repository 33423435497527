import React, { useState, useEffect } from 'react';
import DatePicker, {CalendarContainer} from 'react-datepicker';
import './datePickerComp.css';
import "react-datepicker/dist/react-datepicker.css";


// props:
// date = current date selected or today depending on loading status
// onDateChange = function that reloads data on date change
// day = current selected day
//
//
//

export default function DatePickerComp(props) {
    const [selectedDate, setSelectedDate] = useState(new Date());
    function onDateChange(date) {
        setSelectedDate(date);
        props.onDateChange(date);
    }

    
    const MyContainer = ({ className, children }) => {
        return (
          <div style={{ padding: "16px", background: "#706f6f", color: "#fff" }}>
            <CalendarContainer className={className}>
              <div style={{ background: "#f0f0f0" }}>
                Select a date to view bookings
              </div>
              <div style={{ position: "relative" }}>{children}</div>
            </CalendarContainer>
          </div>
        );
      };


    return (
        <div className='DatePickerComp'>
            <h1 className='DatePickerHeader'>Bookings for: {props.day} - </h1>
            <DatePicker
                selected={selectedDate}
                dateFormat="dd/MM/yyyy"
                onChange={(date) => onDateChange(date)}
                className='DatePickerClass'
                calendarContainer={MyContainer}
            />
        </div>
    )

};


