import React, { useState, useEffect } from 'react';
import './freeRoomButton.css';



// props:
// lesson - the string used to identify the lesson, sent to booking function to book lesson
// bookingFunction - the fucntion used to send api call to book room.
// room - the string used to identify the room, sent to booking function to book lesson
//
//
//

export default function FreeRoomButton(props) {
    return (
        <div className='freeRoomButton' onClick={() => props.bookingFunction('booking', props.room, props.lesson)}>
            Available
        </div>
    ) 

};