import React, { useState, useEffect } from 'react';
import { userAppData } from '../MainApp.js';
import LoadingComp from '../common/loading.js';
import './Dashboard.css';

import DashboardLinks from './DashboardLinks.js';
import QuickLinks from './QuickLinks.js';





export default function Dashboard() {

  const displayName = userAppData['displayName'];
  const firstName = displayName.split(' ')[0];

  return (
    <div className='main_div'>
      <div className='internal_main_div'>
        <div className='dashboard_header_div'>
          <h1 className='admin_header'>
            Welcome {firstName},
          </h1>
        </div>
        <div className='dashboard_main_div'>
          <div className='dashboard_column_div'>
            <QuickLinks />
          </div>
          <div className='dashboard_column_div'>
            <DashboardLinks />
          </div>

        </div>
      </div>
    </div>
  )
};

