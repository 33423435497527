import React from 'react';
import { userAppData } from '../MainApp.js';
import { Outlet } from "react-router-dom";


export default function SeInvt() {

    const seInvntUser = userAppData["invUser"];

    if (seInvntUser >= 1) {
        if (seInvntUser == 1 || seInvntUser == 2 || seInvntUser == 3 || seInvntUser == 4) {
            return (
                <div className='main_div'>
                    <Outlet />
                </div>
            )
        } else {
            return (
                <div>Error - se main SeInvnt.js</div>
            )
        }
    } else {
        return (
            <div>Error - se main SeInvnt.js</div>
        )
    }
};