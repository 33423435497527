import React, { useState, useEffect } from 'react';


export default function MaintenanceSrv() {
  

    return (
      <div className='internal_main_div'>
          Maintenance SRV
        
      </div >
    )
};
