import React, { useState, useEffect } from 'react';
import '../../App.css';
import './seInvntDesktop.css';
import LoadingComp from '../common/loading.js';
import { ApiError } from '../common/apiError.js';
import { useNavigate } from "react-router-dom";
import { SEC_ApiCall } from '../func.js';

export default function SeInvntReports() {

  
  const [dataState, setDataState] = useState([]);
  const [isLoadedState, setIsLoadedState] = useState(false);
  const [apiErrorState, setApiErrorState] = useState(null);
  const [apiErrorTextState, setApiErrorTextState] = useState('');
  const [titleState, setTitleState] = useState('');

  async function apiCall() {
    const url = 'seinventory-v2/reports';
    const urlParams = new URLSearchParams(document.location.search.substring(1));
    const reportType = urlParams.get('rt')
    const reportData = urlParams.get('data')
    setTitleState(reportData);
    var params = {
      "rt": reportType,
      "data": reportData
    };
    try {
      const response = await SEC_ApiCall(url, params, setApiErrorState);
      setDataState(response);
      setIsLoadedState(true);
    } catch (e) {
      setIsLoadedState(true);
      setApiErrorState('error');
      setApiErrorTextState(e);
    }
  };

  useEffect(() => {
    apiCall();
  }, []);



  if (!isLoadedState) {
    return (
      <div className='main_div'>
        <LoadingComp />
      </div>
    )
  } else if (apiErrorState) {
    return (
      <ApiError />
    )
  } else {
    return (
      <div className='internal_main_div'>
        <div className='header_div'>
          <div className='requestTableHeaderItem1'>
            <h1 className='admin_header' >Inventory Report</h1>
            
          </div>
        </div>

        <div className='invnt_report_main_div'>
        <h3>{titleState}</h3>
          {dataState.map((item) =>
            <p key={item.pcName}>{item.pcName}</p>
          )
          }



        </div>
      </div>
    )

  }
}























































/* 
import React, { Component } from 'react';
import '../../App.css';
import { SeInvntReportsDeviceDisplay } from './SeInvntReportsDeviceDisplay.js';
import LoadingComp from '../common/loading.js';
import { SEC_ApiCall } from '../func.js';
import {ApiError} from '../common/apiError.js';

export class SeInvntReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      isLoaded: false,
      apiError: false,
      apiErrorText: '',
    };
  }

  apiCall(params) {
    const url = 'seinventory-v2/reports';
    SEC_ApiCall(url, params).then(data => {
      this.setState({
        isLoaded: true,
        data: data
      })
    }).catch(error => { // catches failed to fetch errors such as no internet when submit button is clicked
      this.setState({
        isLoaded: true,
        apiError: true,
        apiErrorText: error,
      });
    })
  };


  componentDidMount() {
    const urlParams = new URLSearchParams(document.location.search.substring(1));
    const reportType = urlParams.get('rt')

    switch (reportType) {
      case 'all':
        var params = {
          'rt' : 'all'
        };
        this.apiCall(params);
        break;
      case 'hdFull':
        const perc = urlParams.get('perc')
        var params = {
          'rt' : 'hdFull',
          'perc' : perc
        };
        this.apiCall(params);
        break;
      case 'missingUpdates':
        var params = {
          'rt' : 'missingUpdates'
        };
        this.apiCall(params);
        break;
      case 'noReply':
        const days = urlParams.get('days')
        var params = {
          'rt' : 'noReply',
          'days' : days
        };
        this.apiCall(params);
        break;
      case 'osVersion':
        const osVersion = urlParams.get('osVersion')
        var params = {
          'rt' : 'osv',
          'osVersion' : osVersion
        };
        this.apiCall(params);
        break;
      default:
        this.setState({
          isLoaded: true,
          apiError: true,
        });
    }
  };

  render() {

    if (this.state.isLoaded && this.state.apiError) {
      return (
        <div className='internal_main_div'>
          <h1>Reporting Error</h1>
          <ApiError />
        </div>
      )
    } else if (this.state.isLoaded && !this.state.apiError) {
      const urlParams = new URLSearchParams(document.location.search.substring(1));
      const reportType = urlParams.get('rt')
      switch (reportType) {
        case 'all':
          return (
            <div className='internal_main_div'>
              <h1>All System Devices</h1>
              <SeInvntReportsDeviceDisplay type="all" data={this.state.data} />
            </div>
          );
        case 'hdFull':
          return (
            <div className='internal_main_div'>
              <h1>Full Hard Drives</h1>
              <SeInvntReportsDeviceDisplay type="hdFull" data={this.state.data} />
            </div>
          );
        case 'missingUpdates':
          return (
            <div className='internal_main_div'>
              <h1>Devices Missing Updtaes</h1>
              <SeInvntReportsDeviceDisplay type="missingUpdates" data={this.state.data} />
            </div>
          );
        case 'noReply':
          return (
            <div className='internal_main_div'>
              <h1>Devices with no recent contact</h1>
              <SeInvntReportsDeviceDisplay type="noReply" data={this.state.data} />
            </div>
          );
        case 'osVersion':
          return (
            <div className='internal_main_div'>
              <h1>OS Version Filter</h1>
              <SeInvntReportsDeviceDisplay type="osVersion" data={this.state.data} />
            </div>
          );
        default:
          this.setState({
            isLoaded: true,
            apiError: true,
          });
      }
    } else {
      return (
        <LoadingComp />
      )
    }
  }
};

*/