import React, { useState, useEffect } from 'react';
import { userAppData } from '../MainApp.js';
import './OncallDesktop.css';
import './OncallMobile.css';
import LoadingComp from '../common/loading.js';
import { ApiError } from '../common/apiError.js';
import { OnCallCompleteCall } from './OnCallCompleteCall.js';
import { SEC_ApiCall, sortArrayByNumericalProperty } from '../func.js';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import Select from 'react-select';


export default function OncallPreviousRequests() {
    const [dataState, setDataState] = useState([]);
    const [callTypesState, setCallTypesState] = useState([]);
    const [dataFilteredState, setDataFilteredState] = useState([]);
    const [isLoadedState, setIsLoadedState] = useState(false);
    const [apiErrorState, setApiErrorState] = useState(null);
    const [apiErrorTextState, setApiErrorTextState] = useState('');
    const [postApiErrorState, setPostApiErrorState] = useState(null);
    const [resultsPerPageState, setResultsPerPageState] = useState(10);
    const [currentPageState, setCurrentPageState] = useState(1);
    const [numberOfPagesState, setNumberOfPagesState] = useState(1);
    const [pageDataState, setPageDataState] = useState(null);
    const [pupilsFilterState, setPupilsFilterState] = useState([]);

    function paginate(array, page_size, page_number) {
        // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
        return array.slice((page_number - 1) * page_size, page_number * page_size);
    }

    async function apiCall(params) {
        var url = 'oncall/archive';
        try {
            const response = await SEC_ApiCall(url, params, setApiErrorState);
            setDataState(response['data']);
            setCallTypesState(response["callTypes"]);
            let sortedData = sortArrayByNumericalProperty(response['data'], 'submissionEpoch', 'decending')
            setDataFilteredState(sortedData); // add filter
            let resultsSize = response['data'].length;
            setNumberOfPagesState(Math.ceil((resultsSize / resultsPerPageState)));
            let pageData = paginate(response['data'], resultsPerPageState, currentPageState)
            setPageDataState(pageData);
            setIsLoadedState(true);

            let pupilArray = ['All']
            let pupilDict = []

            for (const record of response['data']) {
                let pupilName = record["pupilId"]
                if (!pupilArray.includes(pupilName)) {
                    pupilArray.push(pupilName);
                }
            }

            for (const name of pupilArray) {
                let nameData = { "label": name, "value": name };
                pupilDict.push(nameData);
            }

            setPupilsFilterState(pupilDict);

        } catch (e) {
            setIsLoadedState(true);
            console.log(e);
            setApiErrorState('error');
            setApiErrorTextState(e);
        }
    };

    function onResultsSelectorChange(event) {
        var querySelect = event.target.value;
        setResultsPerPageState(querySelect);
        setCurrentPageState(1);
        let resultsSize = dataState.length;
        setNumberOfPagesState(Math.ceil((resultsSize / querySelect)));
        let pageData = paginate(dataState, querySelect, 1)
        setPageDataState(pageData);
    }

    function nextPage() {
        let currentPage = currentPageState;
        if (currentPage >= numberOfPagesState) {
            setCurrentPageState(currentPage);
        } else {
            let nextPage = currentPage + 1;
            setCurrentPageState(nextPage);
            let pageData = paginate(dataState, resultsPerPageState, nextPage)
            setPageDataState(pageData);
        }
    }

    function prevPage() {
        let currentPage = currentPageState;
        if (currentPage > 1) {
            let nextPage = currentPage - 1;
            setCurrentPageState(nextPage);
            let pageData = paginate(dataState, resultsPerPageState, nextPage)
            setPageDataState(pageData);
        } else {
            setCurrentPageState(1);
        }
    }

    function applyFilter(pupil) {
        let requiredName = pupil.value;

        if (requiredName == 'All') {
            let resultsSize = dataState.length;
            setNumberOfPagesState(Math.ceil((resultsSize / resultsPerPageState)));
            let pageData = paginate(dataState, resultsPerPageState, currentPageState)
            setPageDataState(pageData);
        } else {
            let dataArray = [];

            for (const record of dataFilteredState) {
                let pupilName = record["pupilId"]
                if (pupilName == requiredName) {
                    dataArray.push(record);
                }
            }
            let resultsSize = dataArray.length;
            setNumberOfPagesState(Math.ceil((resultsSize / resultsPerPageState)));
            let pageData = paginate(dataArray, resultsPerPageState, currentPageState)
            setPageDataState(pageData);
        }

    }

    useEffect(() => {
        var params = {};
        apiCall(params);
    }, []);


    if (!isLoadedState) {
        return (
            <div className='main_div'>
                <LoadingComp />
            </div>
        )
    } else if (apiErrorState) {
        return (
            <ApiError />
        )
    } else {
        return (
            <div className='main_div'>
                <div className='internal_main_div'>
                    <div className='oncall_header_div'>
                        <h1 className='admin_header' >OnCall Archived Requests</h1>
                        <div className='requestTableHeaderItem3'>
                            <span className="requestsPerPageLabel">Requests per page</span>
                            <select className='resultsPerPageSelector' defaultValue="10" onChange={onResultsSelectorChange}>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </div>

                    <div className='oncall_filter_div'>
                        <span className="requestsPerPageLabel">Pupil Filter</span>
                        <Select
                            className='oncall_filter_Selector'
                            maxMenuHeight={'200px'}
                            options={pupilsFilterState}
                            placeholder='All'
                            noOptionsMessage={() => 'Unable to find a record of the pupil'}
                            onChange={(e) => applyFilter(e)}
                            isSearchable
                        />

                    </div>

                    <div className='oncall_calls_main_div'>
                        <div className='oncall_calls_complete_calls_div'>
                            {pageDataState.map((item) =>
                                <OnCallCompleteCall
                                    key={item["id"]}
                                    data={item}
                                    showPupilId={true}
                                    callTypes={callTypesState}
                                    apiCall={apiCall}
                                />
                            )
                            }
                        </div>
                    </div>

                    <div className="nextRequestPageDiv">
                        <div className="pageNumberButtons" onClick={prevPage}><FaArrowLeft color="#fb9233" /></div><div className="pageNumberDiv">{currentPageState} / {numberOfPagesState}</div><div className="pageNumberButtons" onClick={nextPage}><FaArrowRight color="#fb9233" /></div>
                    </div>

                </div>
            </div>

        )
    }
};