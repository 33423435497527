import React, { useState, useEffect } from 'react';
import '../../App.css';
import './seInvntDesktop.css';
import './seInvntMobile.css';
import { userAppData } from '../MainApp.js';
import LoadingComp from '../common/loading.js';
import { ApiError } from '../common/apiError.js';
import { useNavigate } from "react-router-dom";
import { SEC_ApiCall } from '../func.js';

export default function SeInvntDashboard() {

  const [dataState, setDataState] = useState([]);
  const [isLoadedState, setIsLoadedState] = useState(false);
  const [apiErrorState, setApiErrorState] = useState(null);
  const [apiErrorTextState, setApiErrorTextState] = useState('');

  let navigate = useNavigate();

  function osVersionUrl(data) {
    var url = '/app/seInvnt/reports?rt=osVersion&data=' + data;
    navigate(url)
  }

  function singleDeviceUrl(name, serial) {
    var url = '/app/seInvnt/device?name=' + name + "&serial=" + serial;
    navigate(url)
  }

  async function apiCall() {
    var url = 'seinventory-v2/dashboard';
    var params = {};
    try {
      const response = await SEC_ApiCall(url, params, setApiErrorState);
      setDataState(response);
      setIsLoadedState(true);
    } catch (e) {
      setIsLoadedState(true);
      setApiErrorState('error');
      setApiErrorTextState(e);
    }
  };

  useEffect(() => {
    apiCall();
  }, []);



  if (!isLoadedState) {
    return (
      <div className='main_div'>
        <LoadingComp />
      </div>
    )
  } else if (apiErrorState) {
    return (
      <ApiError />
    )
  } else {
    return (
      <div className='internal_main_div'>
        <div className='header_div'>
          <div className='requestTableHeaderItem1'>
            <h1 className='admin_header' >SE Inventory</h1>
          </div>
        </div>



        <div className='invnt_dashboard_main_div'>
          <div className='invnt_dashboard_storage_div'>
            <div className='invnt_dashboard_storage_div_headers'>
              <h3 className='invnt_dashboard_headers'>Low Disk Space</h3>
              <h4 className='invnt_dashboard_sub_headers'>(Below 20% Free Space)</h4>
              <table className='invnt_dashboard_storage_table'>
                <tbody>

                </tbody>
              </table>
            </div>
            <div className='invnt_dashboard_storage_scroll_div'>
              <table className='invnt_dashboard_storage_table'>
                <tbody>
                  <tr><td className='invnt_dashboard_table_headers'>Name</td><td className='invnt_dashboard_table_headers'>Drive Free %</td></tr>
                  {dataState["storage"].map((item) =>
                    <tr className='invnt_report_item' key={item.pcName} onClick={() => singleDeviceUrl(item.pcName, item.biosSerial)}>
                      <td>{item.pcName}</td><td>{item.drivePercentageFree}</td>
                    </tr>
                  )
                  }
                </tbody>
              </table>
            </div>

          </div>

          <div className='invnt_dashboard_osVersions_div'>
            <div className='invnt_dashboard_osVersions_inner_div'>
              <div className='invnt_dashboard_storage_div_headers'>
                <h3 className='invnt_dashboard_headers'>Operating System Versions</h3>
                <h4 className='invnt_dashboard_sub_headers'>Total Devices: {dataState["deviceTotal"]}</h4>
              </div>
              <div className='invnt_dashboard_storage_noScroll_div'>
                <table className='invnt_dashboard_storage_table'>
                  <tbody>
                    <tr><td className='invnt_dashboard_table_headers'>Name</td><td className='invnt_dashboard_table_headers'>PC Count</td></tr>
                    {Object.entries(dataState["osVersions"]).map((item, index) =>
                      <tr className='invnt_report_item' key={index} onClick={() => osVersionUrl(item[0])}>
                        <td className="invnt_dashboard_osVersions_osNames">{item[0]}</td><td className="invnt_dashboard_osVersions_osNames">{item[1]}</td>
                      </tr>
                    )
                    }
                  </tbody>
                </table>
              </div>
            </div>

            <div className='invnt_dashboard_osVersions_inner_div'>
              <div className='invnt_dashboard_storage_div_headers'>
                <h3 className='invnt_dashboard_headers'>Recent Connections</h3>
              </div>
              <div className='invnt_dashboard_storage_noScroll_div'>
                <table className='invnt_dashboard_storage_table'>
                  <tbody>
                    <tr><td className='invnt_dashboard_table_headers'>Name</td><td className='invnt_dashboard_table_headers'>Timestamp</td></tr>
                    {dataState["recentConnections"].map((item) =>
                      <tr className='invnt_report_item' key={item.pcName} onClick={() => singleDeviceUrl(item.pcName, item.biosSerial)}>
                        <td className="invnt_dashboard_osVersions_osNames">{item.pcName}</td><td className="invnt_dashboard_osVersions_osNames">{item.runDateTime}</td>
                      </tr>
                    )
                    }
                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </div>




      </div>
    )

  }
};