import React from 'react';
import { userAppData } from '../MainApp.js';
import './SysManagerDesktop.css';
import './SysManagerMobile.css';
import { useNavigate } from "react-router-dom";

import { BsCalendarFill } from 'react-icons/bs';
import { FaPerson } from "react-icons/fa6";
import { FaChildren } from "react-icons/fa6";
import { IoSettingsSharp } from 'react-icons/io5';

function SysManagerHomeMenuLearnersButton() {

    let navigate = useNavigate();

    return (
        <div className='sysManager_Home_Button' onClick={(() => navigate("/pp/sysmanager/learner"))}>
            <FaChildren color='#ffffff' size={120} />
            <h1>Learners</h1>
        </div>
    )
};

function SysManagerHomeMenuStaffButton() {

    let navigate = useNavigate();

    return (
        <div className='sysManager_Home_Button' onClick={(() => navigate("/pp/sysmanager/staff"))}>
            <FaPerson color='#ffffff' size={120} />
            <h1>Staff</h1>
        </div>
    )
};

function SysManagerHomeMenuBookingsButton() {

    let navigate = useNavigate();

    return (
        <div className='sysManager_Home_Button' onClick={(() => navigate("/pp/sysmanager/bookings"))}>
            <BsCalendarFill color='#ffffff' size={120} />
            <h1>Bookings</h1>
        </div>
    )
};

function SysManagerHomeMenuSystemButton() {

    let navigate = useNavigate();

    return (
        <div className='sysManager_Home_Button' onClick={(() => navigate("/pp/sysmanager/system"))}>
            <IoSettingsSharp color='#ffffff' size={120} />
            <h1>Bookings</h1>
        </div>
    )
};





export default function SysManagerHome() {

    let navigate = useNavigate();

    const sysManagerUser = userAppData["sysManager"]['perms'];

    if (sysManagerUser == 0) {
        navigate("/app");
    }


    return (
        <div className='main_div'>
            <div className='internal_main_div'>
                <div className='sysManager_header_div'>
                    <h1 className='admin_header'>System Manager</h1>
                </div>

                <div className='sysManager_main_div'>

                   <SysManagerHomeMenuLearnersButton />

                   <SysManagerHomeMenuStaffButton />

                   <SysManagerHomeMenuBookingsButton />

                   <SysManagerHomeMenuSystemButton />


                </div>
            </div>
        </div>

    )
};