import React, { useState, useEffect } from 'react';
import LoadingComp from './loading.js';
import { useNavigate } from "react-router-dom";
import { userAppData } from '../MainApp.js';
import { ApiError } from './apiError.js';
import '../../App.css';
import './RequestsTable.css';

export function RequestsTable(props) {
  if (!props.isLoaded) {
    return (
      <div className='main_div'>
        <LoadingComp />
      </div>
    )
  } else if (props.apiError) {
    return (
      <ApiError />
    )
  } else {
    if (props.data === undefined || props.data.length == 0) {
      return (
        <div className='table_div'>
          <table className='RequestsTable' id="requests_table" >
            <tbody>
              <RequestsTableHeader headers={props.headers} headerColour={props.headerColour} />
            </tbody>
          </table>
          <p className='NoDataLabel'>No data to show</p>
        </div>
      )
    } else {
            
      return (
        <div className='table_div'>
          
          <table className='RequestsTable' id="requests_table" >
            <tbody>
              <RequestsTableHeader headers={props.headers} headerColour={props.headerColour} />
              <RequestsTableRows rowData={props.data} url={props.url} />
            </tbody>
          </table>


          

        </div>
      )
    }
  }
};



function RequestsTableHeader(props) {
  const headersArray = props.headers;
  return (
    <tr key='table_headers'>
      {headersArray.map((item) =>
        <th key={item.id} className={item.class} style={{ backgroundColor: props.headerColour }}>{item.name}</th>
      )
      }
    </tr>
  )
};


function TableRow(props) {
  let navigate = useNavigate();
  return (
    <tr key={props.mapKey} onClick={() => {
      navigate(props.url);
    }}>
      {props.colData1 ? <td>{props.colData1}</td> : null}
      {props.colData2 ? <td>{props.colData2}</td> : null}
      {props.colData3 ? <td className='requestTableCollapse'>{props.colData3}</td> : null}
      {props.colData4 ? <td className='requestTableCollapse'>{props.colData4}</td> : null}
      {props.colData5 ? <td>{props.colData5}</td> : null}
    </tr>
  );
};


function RequestsTableRows(props) {
  const dataArray = props.rowData;

  if (userAppData["itUser"] == 3) {
    return (
      dataArray.map((item) =>
        <TableRow key={item.requestId} mapKey={item.requestId} url={props.url + item.requestId} colData1={item.requestId} colData2={item.requestTitle} colData3={item.submittingUser} colData4={item.assignedUser} colData5={item.requiredDate} />
      )
    )
  } else if (userAppData["itUser"] == 2) {
    return (
      dataArray.map((item) =>
        <TableRow key={item.requestId} mapKey={item.requestId} url={props.url + item.requestId} colData1={item.requestId} colData2={item.requestTitle} colData3={item.submittingUser} colData4={item.requiredDate} />
      )
    )
  } else {
    return (
      dataArray.map((item) =>
        <TableRow key={item.requestId} mapKey={item.requestId} url={props.url + item.requestId} colData1={item.requestId} colData2={item.requestTitle} colData3={item.assignedUser} colData4={item.requiredDate} />
      )
    )
  }
};


