import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { userAppData } from '../MainApp.js';
import { BrowserRouter as Router, useParams, useNavigate } from "react-router-dom";
import './bookingsHome.css';
import { SEC_ApiCall, SEC_DataApiPost } from '../func.js';
import { ApiError } from '../common/apiError.js';
import LoadingComp from '../common/loading.js';
import Header from '../common/header.js';
import { DailyBookingsTable } from './bookingsTables.js';
import { BookingModal } from '../common/modals.js';
import DatePickerComp from '../common/datePickerComp.js';




export default function BookingsHome() {
    const [bookingsDataState, setBookingsDataState] = useState([]);
    const [isLoadedState, setIsLoadedState] = useState(false);
    const [apiErrorState, setApiErrorState] = useState(null);
    const [postApiErrorState, setPostApiErrorState] = useState(null);
    const [apiErrorTextState, setApiErrorTextState] = useState('');
    const [dateChangeIsLoadedState, setDateChangeIsLoadedState] = useState(false);

    const [headerTextState, setHeaderTextState] = useState('');

    const [lessonStructureState, setLessonStructureState] = useState(null);
    const [roomsDataState, setRoomsDataState] = useState(null);

    const [modalActiveState, setModalActiveState] = useState(false);
    const [modalApiState, setModalApiState] = useState('loading');
    const [modalTypeState, setModalTypeState] = useState(null);
    const [modalRoomState, setModalRoomState] = useState(null);
    const [modalLessonState, setModalLessonState] = useState(null);

    const [requiredDateState, setRequiredDateState] = useState(moment(Date()).format("DDMMyyyy"));
    const [requiredDayState, setRequiredDayState] = useState(moment(Date()).format('dddd'));
    const [requiredDateFormattedState, setDateFormattedState] = useState(moment(Date()).format("DD/MM/yyyy"));
    const [requiredDatePickerState, setRequiredDatePickerState] = useState(null);
    
    const { group } = useParams();

    async function bookingsApiCall() {
        setDateChangeIsLoadedState(false);
        var url = 'bookings';
        var params = {
            "group": group,
            "date": requiredDateState
        };
        try {
            const response = await SEC_ApiCall(url, params, setApiErrorState);
            setBookingsDataState(response);
            setLessonStructureState(response['lessonStructure']);
            setRoomsDataState(response['rooms'])
            setIsLoadedState(true);
            setDateChangeIsLoadedState(true);
        } catch (e) {
            setIsLoadedState(true);
            setApiErrorState('error');
            setApiErrorTextState(e);
        }
    };

    useEffect(() => {
        let headerText = "Bookings - " + group.replace(/_/g, " ");
        setHeaderTextState(headerText);
        bookingsApiCall();
    }, [group, requiredDateState]);


    function reload() {
        let headerText = "Bookings - " + group.replace(/_/g, " ");
        setHeaderTextState(headerText);
        bookingsApiCall();
    };

    async function bookingFunction(type, room, lessonId) {
        setModalApiState('loading');
        setModalLessonState(lessonId.replace(/_/g, " "));
        setModalRoomState(room.replace(/_/g, " "));
        setModalActiveState(true);
        setModalTypeState(type);

        let formData = {
            'type': type,
            "group": group,
            "date": requiredDateState,
            "room": room,
            "lesson": lessonId
        };

        let params = {};

        var url = 'bookings';
        try {
            const response = await SEC_DataApiPost(url, params, formData, setPostApiErrorState);
            if (response['data'] == 'success') {
                setModalApiState('success');
                reload();
            } else {
                setApiErrorState('error');
                reload();
            }
        } catch (e) {
            setApiErrorState('error');
            setApiErrorTextState(e);
            reload();
        }
    }

    function onDateSelectorChange(date) {
        let dateFormatted = moment(date).format("DD/MM/yyyy");
        let dateApiFormat = moment(date).format("DDMMyyyy");
        setRequiredDateState(dateApiFormat);
        setDateFormattedState(dateFormatted);
        const dow = moment(date).format('dddd');
        setRequiredDayState(dow);
    }

    let navigate = useNavigate();

    const bookingsUser = userAppData['bookingsData']['perms'];

    if (bookingsUser == 0) {
        navigate("/app");
    }

    if (!isLoadedState) {
        return (
            <LoadingComp />
        )
    } else if (apiErrorState) {
        return (
            <ApiError />
        )
    } else if (isLoadedState && !apiErrorState) {

        return (
            <div className='internal_main_div'>

                <BookingModal room={modalRoomState} lesson={modalLessonState} day={requiredDayState} date={requiredDateFormattedState} modalActiveState={modalActiveState} setStateFunction={setModalActiveState} apiState={modalApiState} onModalClose={null} type={modalTypeState} />

                <Header text={headerTextState} />


                <DatePickerComp date={requiredDatePickerState} onDateChange={onDateSelectorChange} day={requiredDayState} />

                <DailyBookingsTable dateChangeIsLoadedState={dateChangeIsLoadedState} lessonStructure={lessonStructureState} roomsData={roomsDataState} cancelBookingFunction={bookingFunction} bookingFunction={bookingFunction} />

            </div>
        )
    }
};
