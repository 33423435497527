import '@aws-amplify/ui-react/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './MainApp.css';
import React, { useState, useEffect } from 'react';
import fullLogo from '../SE_Logo.svg';

//import { ForgotPassword, SignIn, withAuthenticator } from 'aws-amplify-react';
import { withAuthenticator, Image } from '@aws-amplify/ui-react';
import { Amplify } from "aws-amplify";


import { Outlet } from "react-router-dom";

import Sidebar from './sidebar.js';
import { SEC_ApiCall } from './func.js';
import LoadingComp from './common/loading.js';
import { ApiError } from './common/apiError.js';



export const userAppData = [];

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'eu-west-2',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'eu-west-2_SpMZaSqje',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '4nn1goool24rhgr3ftcvn4g2hh',

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_PASSWORD_AUTH',

    storage: sessionStorage,
  }
});

function getUserAppData() {
  // id token
  for (let key in sessionStorage) {
    let n = key.search("idToken");
    if (n !== -1) {
      let tokenKey = key
      let token = sessionStorage.getItem(tokenKey);
      userAppData['idToken'] = token;
    }
  }
  // access token
  for (let accessKey in sessionStorage) {
    let a = accessKey.search("accessToken");
    if (a !== -1) {
      let accessTokenKey = accessKey
      let accessToken = sessionStorage.getItem(accessTokenKey);
      userAppData['accessToken'] = accessToken;
    }
  }
  // email 
  for (let emailKey in sessionStorage) {
    let x = emailKey.search("userData");
    if (x !== -1) {
      let emailDataKey = emailKey
      let emailData = sessionStorage.getItem(emailDataKey);
      let emailObj = JSON.parse(emailData);
      let emailUserAttributes = emailObj['UserAttributes'];

      for (let emailItem in emailUserAttributes) {
        let emailAttributeItem = emailUserAttributes[emailItem]['Name'];
        if (emailAttributeItem === 'email') {
          let email = emailUserAttributes[emailItem]['Value'];
          userAppData['email'] = email;
        }

      }
    }
  }
  // school code 
  for (let scKey in sessionStorage) {
    let x = scKey.search("userData");
    if (x !== -1) {
      let scDataKey = scKey
      let scData = sessionStorage.getItem(scDataKey);
      let scObj = JSON.parse(scData);
      let scUserAttributes = scObj['UserAttributes'];

      for (let scItem in scUserAttributes) {
        let scAttributeItem = scUserAttributes[scItem]['Name'];
        if (scAttributeItem === 'custom:schoolCode') {
          let schoolCode = scUserAttributes[scItem]['Value'];
          userAppData['schoolCode'] = schoolCode;
        }
      }
    }
  }
  // display name
  for (let nameKey in sessionStorage) {
    let y = nameKey.search("userData");
    if (y !== -1) {
      let nameDataKey = nameKey
      let nameData = sessionStorage.getItem(nameDataKey);
      let nameObj = JSON.parse(nameData);
      let nameUserAttributes = nameObj['UserAttributes'];
      for (let nameItem in nameUserAttributes) {
        let nameAttributeItem = nameUserAttributes[nameItem]['Name'];
        if (nameAttributeItem === 'name') {
          let name = nameUserAttributes[nameItem]['Value'];
          userAppData['displayName'] = name;
        }

      }
    }
  }
}


function MainApp() {
  const [desktopView, setDesktopViewState] = useState(true);  // used by sidebar to decide what size the icons are as mobile view icons are smaller
  const [fullSidebar, setFullSidebarState] = useState(true); // used to trigger the movement between full and minimised sidebar, if true sidebar is full
  const [data, setDataState] = useState(null);
  const [isLoaded, setIsLoadedState] = useState(false);
  const [apiError, setApiErrorState] = useState(false);
  const [apiErrorText, setApiErrorTextState] = useState(null);

  async function apiCall() {
    var url = 'perms/';
    var params = {};
    try {
      const response = await SEC_ApiCall(url, params, setApiErrorState);
      let responseData = response;
      userAppData['displayName'] = responseData['displayName'];
      userAppData['itUser'] = responseData['itUser'];
      userAppData['reproUser'] = responseData['reproUser'];
      userAppData['invUser'] = responseData['invUser'];
      userAppData['bookingsData'] = JSON.parse(responseData['bookingsData']);
      userAppData['maintenanceUser'] = responseData['maintenanceUser'];
      userAppData['onCall'] = JSON.parse(responseData['onCallData']);
      userAppData['sysManager'] = JSON.parse(responseData['sysManagerData']);
      userAppData['cogId'] = responseData['cogId'];
      userAppData['schoolName'] = responseData['schoolName'];
      userAppData['dashboardLinks'] = JSON.parse(responseData['dashboardLinks']);
      console.log(userAppData);
      setIsLoadedState(true);
      setDataState(response);
    } catch (e) {
      setApiErrorState(true);
      setIsLoadedState(true);
      setApiErrorTextState(e);
      console.log(e);
    }
  }

  useEffect(() => {
    let viewportwidth = window.innerWidth;

    if (viewportwidth <= 700) {
      setDesktopViewState(false);
      setFullSidebarState(false);
    }
    apiCall();
    localStorage.clear();
    getUserAppData();
  }, []);

  if (!isLoaded) {
    return (
      <LoadingComp />
    )
  } else if (apiError) {
    return (
      <ApiError />
    )
  } else if (isLoaded && !apiError) {

    return (
      <div className="App">
        <Sidebar fullSidebarValue={fullSidebar} setFullSidebarState={setFullSidebarState} desktopView={desktopView} />

        <div className={fullSidebar ? 'moduleDiv-full' : 'moduleDiv-min'}>
          <Outlet />

        </div>
      </div>
    );
  }
};


function Header() {
  return (
    <div className='loginPageLogoDiv'>
      <Image
        alt="logo"
        src={fullLogo}
        width={'80%'}
        padding={20}
      />
    </div>
  );
}

function Footer() {
  return (
    <div className='loginPageFooterTextDiv'>
    <p className='loginPageFooterText'>App Version: 1.2</p>
    <p className='loginPageFooterText'> &copy; School Engine 2024</p>
    </div>
  );
}





export default withAuthenticator(MainApp, {
  components: {
    SignIn: {
      Header: Header,
      Footer: Footer,
    },

  }
});