import React from 'react';
import pencil1 from '../assets/pencil1.jpg';
import './apiError.css';

export function ApiError() {
    return (
        <div className="mainErrorDiv">
          <h1 className='requestSubmittedH1'>We're Sorry</h1>
          <h2 className='requestSubmittedH2'>There has been an error retrieving the data. If this issue continues please contact us.</h2>
          <button className='returnButton' type='button' onClick={() => { window.history.back() }}>
            <img className='returnPencilImg' src={pencil1} alt="SE_Pencil" />
          </button>
        </div>
    )
};