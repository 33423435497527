import React, { useState, useEffect } from 'react';
import './sidebar.css';
import { Auth } from 'aws-amplify';
import fullLogo from '../SE_Logo.svg';
import { useNavigate } from "react-router-dom";
import minLogo from '../SE_min_logo.svg';
import { MdComputer } from 'react-icons/md';
import { IoDocuments, IoDocumentAttach, IoSettingsSharp } from 'react-icons/io5';
import { FaSitemap, FaBriefcaseMedical, FaTools, FaBell } from 'react-icons/fa';
import { BiError, BiLogOut, BiCctv } from 'react-icons/bi';
import { FiMinimize2 } from 'react-icons/fi';
import { BsArrowsAngleExpand, BsCalendarFill } from 'react-icons/bs';
import { userAppData } from './MainApp.js';
import { accessBoolean } from './func.js';


function iconFinder(icon, desktopView) {
    let size = '45px';
    if (!desktopView) {
        size = '35px';
    }

    switch (icon) {
        case 'IT Support':
            return (
                <MdComputer color='#ffffff' size={size} />
            );
        case 'Reprographics':
            return (
                <IoDocuments color='#ffffff' size={size} />
            );
        case 'SE Inventory':
            return (
                <FaSitemap color='#ffffff' size={size} />
            );
        case 'CCTV Requests':
            return (
                <BiCctv color='#ffffff' size={size} />
            );
        case 'Bookings':
            return (
                <BsCalendarFill color='#ffffff' size={size} />
            );
        case 'Maintenance':
            return (
                <FaTools color='#ffffff' size={size} />
            );
        case 'Medical':
            return (
                <FaBriefcaseMedical color='#ffffff' size={size} />
            );
        case 'Admin Requests':
            return (
                <IoDocumentAttach color='#ffffff' size={size} />
            );
        case 'System Manager':
            return (
                <IoSettingsSharp color='#ffffff' size={size} />
            );
        case 'OnCall':
            return (
                <FaBell color='#ffffff' size={size} />
            );
        default:
            return (
                <BiError color='#ffffff' size={size} />
            );
    }
}

function SidebarItemWithDropdown(props) {
    let navigate = useNavigate();

    function onDropdownItemClick(url) {
        navigate(url);
        if (!props.desktopViewState) { // if mobile view
            let currentState = props.dropdownState;
            props.dropdownStateChange(props.name, currentState);
            props.setFullSidebarState(false);
        }
    }

    function onMainItemClick() {
        let currentState = props.dropdownState;
        props.dropdownStateChange(props.name, currentState);
    }

    return (
        <div className="sidebar-item-padding-div">
            <div className='sidebar-item'>
                <div className='sidebar-item-main-item' onClick={() => onMainItemClick()}>
                    {iconFinder(props.name, props.desktopViewState)}
                    <span className={props.fullSidebarValue ? 'sidebar-item-text' : 'sidebar-item-text-min'}>{props.name}</span>
                </div>

                <div className={props.dropdownState ? 'sidebar-item-dropdown-outer' : 'sidebar-item-dropdown-outer-hide'}>
                    {props.dropdowns.map((dropdownitem) => {
                        return (
                            <div key={dropdownitem["name"]} className='sidebar-item-dropdown-item' onClick={() => (onDropdownItemClick(dropdownitem["url"]))}>
                                <span className={props.fullSidebarValue ? 'sidebar-dropdown-item-text' : 'sidebar-item-text-min'}>{dropdownitem["name"]}</span>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}





function SidebarItemWithoutDropdown(props) {
    let navigate = useNavigate();

    function onItemClick(url) {
        navigate(url)
        props.closeAllDropdowns();
        if (!props.desktopViewState) { // if mobile view
            let currentState = props.dropdownState;
            props.dropdownStateChange(props.name, currentState);
            props.setFullSidebarState(false);
        }
    }

    return (
        <div className="sidebar-item-padding-div" onClick={() => onItemClick(props.url)}>
            <div className='sidebar-item'>
                <div className='sidebar-item-main-item'>
                    {iconFinder(props.name, props.desktopViewState)}
                    <span className={props.fullSidebarValue ? 'sidebar-item-text' : 'sidebar-item-text-min'}>{props.name}</span>
                </div>
            </div>
        </div>
    )
}


export default function Sidebar(props) {
    const [itsupportDropdownState, setItsupportDropdownState] = useState(false);
    const [reproDropdownState, setReproDropdownState] = useState(false);
    const [maintenanceDropdownState, setMaintenanceDropdownState] = useState(false);
    const [oncallDropdownState, setOncallDropdownState] = useState(false);
    const [bookingsDropdownState, setBookingsDropdownState] = useState(false);


    let navigate = useNavigate();

    function returnToDashboard() {
        navigate('/app/dashboard')
    }

    function toggleSidebarVisibilityStatus() { // toggles between full and minimised sidebar
        // cancel all dropdowns
        setItsupportDropdownState(false);
        setReproDropdownState(false);
        setBookingsDropdownState(false);
        setMaintenanceDropdownState(false);
        setOncallDropdownState(false);

        if (props.fullSidebarValue) {
            props.setFullSidebarState(false);
        } else {
            props.setFullSidebarState(true);
        }
    }

    function closeAllDropdowns() {
        setItsupportDropdownState(false);
        setReproDropdownState(false);
        setMaintenanceDropdownState(false);
        setOncallDropdownState(false);
        setBookingsDropdownState(false);
    }


    function handleDropdownTrigger(name, currentState) {
        props.setFullSidebarState(true);
        setItsupportDropdownState(false);
        setBookingsDropdownState(false);
        setReproDropdownState(false);
        setMaintenanceDropdownState(false);
        setOncallDropdownState(false);
        switch (name) {
            case 'IT Support':
                if (currentState) {
                    setItsupportDropdownState(false);
                } else {
                    setItsupportDropdownState(true);
                }
                break;
            case 'Reprographics':
                if (currentState) {
                    setReproDropdownState(false);
                } else {
                    setReproDropdownState(true);
                }
                break;
            case 'Maintenance':
                if (currentState) {
                    setMaintenanceDropdownState(false);
                } else {
                    setMaintenanceDropdownState(true);
                }
                break;
            case 'OnCall':
                if (currentState) {
                    setOncallDropdownState(false);
                } else {
                    setOncallDropdownState(true);
                }
                break;
            case 'Bookings':
                if (currentState) {
                    setBookingsDropdownState(false);
                } else {
                    setBookingsDropdownState(true);
                }
                break;
            default:
                console.log('dropdown switch case code error');
        }
    }

    async function onSignOutClick() {
        await Auth.signOut()
    }

    // IT Support Sidebar Code
    const itSupportLink = accessBoolean(userAppData['itUser']);
    const itLinkDropdowns = []
    if (itSupportLink) {
        if (userAppData['itUser'] == 1 || userAppData['itUser'] == 2) {
            itLinkDropdowns.push({ "name": "Request Form", "url": "/app/ItSupport/form" });
            itLinkDropdowns.push({ "name": "My IT Requests", "url": "/app/ItSupport/requests" });
        } else if (userAppData['itUser'] == 3 || userAppData['itUser'] == 4) {
            itLinkDropdowns.push({ "name": "IT Request Form", "url": "/app/ItSupport/form" });
            itLinkDropdowns.push({ "name": "View Requests", "url": "/app/ItSupport/requests" });
            //itLinkDropdowns.push({ "name": "IT Reports", "url": "/app/ItSupport/reports" });
        } else {
            console.log('error'); // do something more useful here like redirect or update userappdata to 0
        }
    }

    // Reprographics Sidebar Code
    const reproLink = accessBoolean(userAppData['reproUser']);
    const reproDropdowns = []
    if (reproLink) {
        if (userAppData['reproUser'] == 1 || userAppData['reproUser'] == 2) {
            reproDropdowns.push({ "key": "1", "name": "Reprographics Request", "url": "/app/repro/form" });
            reproDropdowns.push({ "key": "2", "name": "My Requests", "url": "/app/repro/requests" });
        } else if (userAppData['reproUser'] == 3 || userAppData['reproUser'] == 4) {
            reproDropdowns.push({ "key": "1", "name": "Request Form", "url": "/app/repro/form" });
            reproDropdowns.push({ "key": "2", "name": "View Requests", "url": "/app/repro/requests" });
        } else {
            console.log('error'); // do something more useful here like redirect or update userappdata to 0
        }
    }


    // Bookings Sidebar Code
    const bookingsLink = accessBoolean(userAppData['bookingsData']['perms']);
    const bookingsDropdowns = [];
    let bookingsGroups = userAppData['bookingsData']['groups'];
    if (bookingsLink) {
        bookingsGroups.forEach((item) => {
            let url = "/app/bookings/" + item.replace(/ /g, "_");
            bookingsDropdowns.push({ "name": item, "url": url });
        });
        if (userAppData['bookingsData']['perms'] == 2) {
            bookingsDropdowns.push({ "name": "Manage", "url": "/app/bookings/manage" });
        } // do something more useful here like redirect or update userappdata to 0, using else after if 
    }

    // SE Inventory Sidebar Code
    const seinvntLink = accessBoolean(userAppData['invUser']);

    // System Manager Sidebar Code
    const sysManagerLink = accessBoolean(userAppData['sysManager']['perms']);

    // OnCall Sidebar Code
    const oncallLink = accessBoolean(userAppData['onCall']['perms']);
    const oncallDropdowns = [];
    if (oncallLink) {
        oncallDropdowns.push({ "name": "New/Active Calls", "url": "/app/oncall" });
        oncallDropdowns.push({ "name": "Archived Requests", "url": "/app/oncall/archive" });
        if (userAppData["onCall"]['canViewReports']) {
            oncallDropdowns.push({ "name": "Reports", "url": "/app/oncall/reports" });

        }
    }

    // Maintenance Sidebar Code
    const maintenanceLink = accessBoolean(userAppData['maintenanceUser']);
    const maintenanceDropdowns = []
    if (maintenanceLink) {
        if (userAppData['maintenanceUser'] == 1 || userAppData['maintenanceUser'] == 2) {
            maintenanceDropdowns.push({ "name": "Request Form", "url": "/app/maintenance/form" });
            maintenanceDropdowns.push({ "name": "My Requests", "url": "/app/maintenance/requests" });
        } else if (userAppData['maintenanceUser'] == 3 || userAppData['maintenanceUser'] == 4) {
            maintenanceDropdowns.push({ "name": "Request Form", "url": "/app/maintenance/form" });
            maintenanceDropdowns.push({ "name": "View Requests", "url": "/app/maintenance/requests" });
            //maintenanceDropdowns.push({ "name": "Reports", "url": "/app/maintenance/reports" });
        } else {
            console.log('error'); // do something more useful here like redirect or update userappdata to 0
        }
    }











    return (
        <div className={props.fullSidebarValue ? 'sidebar-full' : 'sidebar-minimised'}>
            <div className='sidebar-logo-div' onClick={() => returnToDashboard()}><img className={props.fullSidebarValue ? 'sidebar-logo-full' : 'sidebar-logo-min'} src={props.fullSidebarValue ? fullLogo : minLogo} alt='logo' />
                <h1 className={props.fullSidebarValue ? 'sidebar-schoolName' : 'sidebar-schoolName-minimised'}>{userAppData['schoolName']}</h1>
                <h1 className={props.fullSidebarValue ? 'sidebar-displayName' : 'sidebar-displayName-minimised'}>{userAppData['displayName']}</h1>
            </div>
            <div>
                {/* IT SUPPORT ITEM */}
                {itSupportLink && <SidebarItemWithDropdown name="IT Support" dropdownState={itsupportDropdownState} dropdownStateChange={handleDropdownTrigger} fullSidebarValue={props.fullSidebarValue} setFullSidebarState={props.setFullSidebarState} desktopViewState={props.desktopView} dropdowns={itLinkDropdowns} />}

                {/* REPRO ITEM */}
                {reproLink && <SidebarItemWithDropdown name="Reprographics" dropdownState={reproDropdownState} dropdownStateChange={handleDropdownTrigger} fullSidebarValue={props.fullSidebarValue} setFullSidebarState={props.setFullSidebarState} desktopViewState={props.desktopView} dropdowns={reproDropdowns} />}

                {/* BOOKINGS ITEM */}
                {bookingsLink && <SidebarItemWithDropdown name="Bookings" dropdownState={bookingsDropdownState} dropdownStateChange={handleDropdownTrigger} fullSidebarValue={props.fullSidebarValue} closeAllDropdowns={closeAllDropdowns} setFullSidebarState={props.setFullSidebarState} desktopViewState={props.desktopView} dropdowns={bookingsDropdowns} />}

                {/* SE INVENTORY ITEM */}
                {seinvntLink && <SidebarItemWithoutDropdown name="SE Inventory" fullSidebarValue={props.fullSidebarValue} dropdownStateChange={handleDropdownTrigger} closeAllDropdowns={closeAllDropdowns} setFullSidebarState={props.setFullSidebarState} desktopViewState={props.desktopView} url="/app/seinvnt" />}

                {/* MAINTENANCE ITEM */}
                {maintenanceLink && <SidebarItemWithDropdown name="Maintenance" dropdownState={maintenanceDropdownState} dropdownStateChange={handleDropdownTrigger} fullSidebarValue={props.fullSidebarValue} setFullSidebarState={props.setFullSidebarState} desktopViewState={props.desktopView} dropdowns={maintenanceDropdowns} />}

                {/* ONCALL ITEM */}
                {oncallLink && <SidebarItemWithDropdown name="OnCall" dropdowns={oncallDropdowns} dropdownState={oncallDropdownState} fullSidebarValue={props.fullSidebarValue} dropdownStateChange={handleDropdownTrigger} closeAllDropdowns={closeAllDropdowns} setFullSidebarState={props.setFullSidebarState} desktopViewState={props.desktopView} url="/app/oncall" />}

                {/* SE INVENTORY ITEM */}
                {sysManagerLink && <SidebarItemWithoutDropdown name="System Manager" fullSidebarValue={props.fullSidebarValue} dropdownStateChange={handleDropdownTrigger} closeAllDropdowns={closeAllDropdowns} setFullSidebarState={props.setFullSidebarState} desktopViewState={props.desktopView} url="/app/sysmanager" />}

            </div>


            <div className='sidebar-minimise-div' onClick={() => toggleSidebarVisibilityStatus()}>
                {props.fullSidebarValue ? <FiMinimize2 color='#ffffff' size='35px' /> : <BsArrowsAngleExpand color='#ffffff' size='35px' />};
                <span className={props.fullSidebarValue ? 'sidebar-bottom-item-text' : 'sidebar-item-text-min'}>Minimise Sidebar</span>
            </div>
            <div className='sidebar-signout-div' onClick={() => onSignOutClick()}>
                <BiLogOut color='#ffffff' size='35px' />
                <span className={props.fullSidebarValue ? 'sidebar-bottom-item-text' : 'sidebar-item-text-min'}>Sign Out</span>
            </div>
            <div className='sidebar-version-div'>
                <span className={props.fullSidebarValue ? 'sidebar-bottom-version-text' : 'sidebar-item-text-min'}>App Version: 1.2</span>
            </div>


        </div>
    )
}