import React from 'react';
import './Maintenance.css';



export default function MaintenanceRequests() {
   

    
    return (
        <div className='main_div'>
            Maintenance Requests
        </div>
    )
};