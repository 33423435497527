import React, { useState, useEffect } from 'react';
import './cancelRoomButton.css';



// props:
// lesson - the string used to identify the lesson, sent to booking function to book lesson
// bookingFunction - the fucntion used to send api call to book room.
// room - the string used to identify the room, sent to booking function to book lesson
//
//
//

export default function CancelRoomButton(props) {
    return (
        <div className='cancelRoomButton' onClick={() => props.cancelBookingFunction('cancel', props.room, props.lesson)}>
                {props.user}
                <br />
                Cancel Booking
                <br />
                {props.class}
        </div>
    )

};