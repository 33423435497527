import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './OnCallReportGroupSummary.css';
import LoadingComp from '../common/loading.js';
import { ApiError } from '../common/apiError.js';
import { SEC_ApiCall } from '../func.js';
import { PieChartComponent } from './chartComponents/PieChartComponent';
import { findSchoolPeriod } from './OnCallFunc.js';

import { LessonTimesCountTable } from './tableComponents/LessonTimesCountTable.js';
import { WeeklyCallTrendsLineChart } from './chartComponents/WeeklyCallTrendsLineChart.js';
import { PupilCallTypeSummaryTable } from './tableComponents/PupilCallTypeSummaryTable.js';


import Chart from 'chart.js/auto';
// import html2canvas from 'html2canvas';   remove from npm
// import jsPDF from 'jspdf';   remove from npm

export default function OnCallReportGroupSummary() {
    const [dataState, setDataState] = useState(null);
    const [filteredDataState, setFilteredDataState] = useState(null);
    const [selectedLesson, setSelectedLesson] = useState(null);
    const [selectedCallType, setSelectedCallType] = useState(null);

    const [isLoadedState, setIsLoadedState] = useState(false);
    const [apiErrorState, setApiErrorState] = useState(false);
    const [apiErrorTextState, setApiErrorTextState] = useState('');
    const [totalCallCountState, setTotalCallCountState] = useState(0);
    const [allCallsArrayState, setAllCallsArrayState] = useState(null);
    const [callTypeColoursState, setAllCallTypeColoursState] = useState(null);

    const { type, groupName } = useParams();




    function handleLessonSelect(selectedPeriod) {
        // Check if the selected period is already the active filter
        if (selectedPeriod === selectedLesson) {
            // If so, reset by clearing the selected lesson and using original data
            setSelectedLesson(null);
            setFilteredDataState(dataState);  // Reset to original data
            let allUnfilteredRecords = dataState.pupilRecords.flatMap(pupil => pupil.onCallRecords);
            setAllCallsArrayState(allUnfilteredRecords);
            setTotalCallCountState(allUnfilteredRecords.length);
        } else {
            // Set the new selected lesson and filter data
            setSelectedLesson(selectedPeriod);

            // Filter pupil records and their call records based on the selected lesson period
            const filteredPupils = dataState.pupilRecords.map(pupil => ({
                ...pupil,
                onCallRecords: pupil.onCallRecords.filter(call => {
                    const callPeriod = findSchoolPeriod(call.submissionEpoch, dataState.timetableTimings);
                    return callPeriod === selectedPeriod;
                })
            })).filter(pupil => pupil.onCallRecords.length > 0); // Keep only pupils with calls in that period

            // Collect all filtered call records for the pie chart
            const allFilteredRecords = [];
            filteredPupils.forEach(pupil => {
                allFilteredRecords.push(...pupil.onCallRecords);
            });

            // Update filtered data state to only include pupils with calls in the selected period
            setFilteredDataState({
                ...dataState,
                pupilRecords: filteredPupils
            });

            // Update the state used for the pie chart and other visualizations
            setAllCallsArrayState(allFilteredRecords);
            setTotalCallCountState(allFilteredRecords.length);
        }
    };

    function handleCallTypeSelect(selectedType) {
        if (selectedType === selectedCallType) {
            // If the same type is clicked again, reset the filters
            setSelectedCallType(null);
            setFilteredDataState(dataState);  // Reset to original data
            let allUnfilteredRecords = dataState.pupilRecords.flatMap(pupil => pupil.onCallRecords);
            setAllCallsArrayState(allUnfilteredRecords);
            setTotalCallCountState(allUnfilteredRecords.length);
        } else {
            // Set the new selected call type and apply filter
            setSelectedCallType(selectedType);  // Set this to selectedType, not null

            // Filter pupil records and their call records based on the selected call type
            const filteredPupils = dataState.pupilRecords.map(pupil => ({
                ...pupil,
                onCallRecords: pupil.onCallRecords.filter(call => call.callType === selectedType)
            })).filter(pupil => pupil.onCallRecords.length > 0);  // Keep only pupils with calls of that type

            // Collect all filtered call records for the pie chart
            const allFilteredRecords = filteredPupils.flatMap(pupil => pupil.onCallRecords);

            // Update filtered data state to only include pupils with calls of the selected type
            setFilteredDataState({
                ...dataState,
                pupilRecords: filteredPupils
            });

            // Update the state used for the pie chart and other visualizations
            setAllCallsArrayState(allFilteredRecords);
            setTotalCallCountState(allFilteredRecords.length);
        }
    };




    async function apiCall(params) {
        var url = 'oncall/reports/groupSummary';
        try {
            const response = await SEC_ApiCall(url, params, setApiErrorState);
            const objectResponse = JSON.parse(response);
            setDataState(objectResponse);  // Update dataState with the new data
            setFilteredDataState(objectResponse);

            const allRecords = [];

            objectResponse.pupilRecords.forEach(record => {
                allRecords.push(...record.onCallRecords);
            });

            // sets the data states for the pie chart component
            setAllCallsArrayState(allRecords); // full array of all calls
            setAllCallTypeColoursState(objectResponse.onCallType); // call type colour codes from api call

            setTotalCallCountState(allRecords.length);
            setIsLoadedState(true);
            
        } catch (e) {
            setApiErrorState(true);
            console.log(e);
            setIsLoadedState(true);
            setApiErrorTextState(e.message);  // assuming e has a message property
        }
    };

    useEffect(() => {
        var params = {
            'groupName': groupName,
            'type': type
        };
        apiCall(params);
    }, [groupName, type]);



    if (!isLoadedState) {
        return <LoadingComp />;
    } else if (apiErrorState) {
        return <ApiError message={apiErrorTextState} />;
    } else {
        return (
            <div id="main_div" className='main_div'>
                <h1 className='oncall_reports_groupSummary_mainHeader'>Summary Report - {type.charAt(0).toUpperCase() + type.slice(1)}: {groupName}</h1>
                <h1 className='oncall_reports_groupSummary_subHeader'>Total Calls: {totalCallCountState}</h1>
                <div className='oncall_reports_groupSummary_summaryDiv'>

                    <div className='oncall_reports_groupSummary_pieChartDiv'>
                        <h1 className='oncall_reports_groupSummary_divHeader'>Call Types</h1>
                        <h1 className='oncall_reports_groupSummary_divSubHeader'>Click to filter</h1>
                        <PieChartComponent
                            callsArray={allCallsArrayState}
                            callTypeColours={callTypeColoursState}
                            onCallTypeSelect={handleCallTypeSelect}  // Pass this function as a prop
                        />


                    </div>

                    <div className='oncall_reports_groupSummary_tableDiv'>
                        <h1 className='oncall_reports_groupSummary_divHeader'>Lesson Times</h1>
                        <h1 className='oncall_reports_groupSummary_divSubHeader'>Based on call submission time</h1>
                        <h1 className='oncall_reports_groupSummary_divSubHeader'>Click a lesson to filter and click it again to reset</h1>
                        <LessonTimesCountTable
                            data={allCallsArrayState}  // Uses allCallsArrayState
                            timetableTimings={dataState.timetableTimings}
                            onLessonSelect={handleLessonSelect}
                            selectedLesson={selectedLesson}
                        />
                    </div>

                </div>

                <div className='oncall_reports_groupSummary_lineChartDiv'>
                    <h1 className='oncall_reports_groupSummary_divHeader'>Weekly Call Trends</h1>
                    <h1 className='oncall_reports_groupSummary_divSubHeader'>Date is the Monday of that week</h1>
                    <div className='oncall_reports_groupSummary_lineChartInnerDiv'>
                        <WeeklyCallTrendsLineChart data={allCallsArrayState} />
                    </div>
                </div>

                <div className='oncall_reports_groupSummary_pupilsTableDiv'>
                    <h1 className='oncall_reports_groupSummary_divHeader'>Pupils List</h1>
                    <h1 className='oncall_reports_groupSummary_divSubHeader'>Click a column heading to sort</h1>
                    <h1 className='oncall_reports_groupSummary_divSubHeader'>Select a pupil to see their individual report</h1>
                    <PupilCallTypeSummaryTable
                        pupilRecords={filteredDataState.pupilRecords}  // Should use filteredDataState
                        callTypes={dataState.onCallType}
                    />
                </div>
            </div>
        );
    }
}
