import React, { useState, useEffect } from 'react';
import { userAppData } from '../MainApp.js';
import './DashboardLinks.css'


export default function DashboardLinks() {

    const dashboardLinks = userAppData['dashboardLinks'];



    return (
        <div className='dashboardLinks_main_div'>
            <h1 className='dashboard_tabHeaders'>External Links</h1>
            {dashboardLinks.map((item) =>
                <a key={item.text} href={item.link} target="_blank">
                    <div className='dashboardLinks_individual_link'>
                        <img className='dashboardLinks_img' src={item.image} />
                        <p className='dashboardLinks_p'>{item.text}</p>
                    </div>
                </a>
            )}

        </div>
    )
};