import React from 'react';
import LoadingGif from '../assets/Loading.gif';
import './loading.css';


export default function LoadingComp() {
    return (
        <div className='mainLoadingDiv'>
            <img className='loading_comp' src={LoadingGif} alt='Loading...'></img>
        </div>
    )
};
